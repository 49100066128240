const mapData = [
  {
    img: "Mendoza.svg",
    title: "Provincia de Mendoza",
    content:
      'En 2017, el gobierno de Mendoza <a href="https://www.elsol.com.ar/reconocimiento-facial-hallaron-a-mas-de-100-personas-con-pedido-de-captura" target="_blank" rel="noopener noreferrer">desplegó</a> uno de los sistemas de reconocimiento facial más invasivos del país. Las fuerzas de seguridad cuentan con cámaras móviles y vehículos equipados con cámaras que pueden detectar el rostro de personas. Las autoridades se negaron a brindar información sobre el hardware y software de este sistema, bajo el argumento de que se podría afectar la seguridad pública.',
  },

  {
    img: "SantaFe.svg",
    title: "Rosario (Santa Fe)",
    content:
      'En marzo de 2023 el Gobierno Nacional y el gobernador firmaron un convenio marco de colaboración y cooperación técnica con el ReNaPer que habilita a las fuerzas de seguridad provinciales para la utilización del Sistema de Identificación Segura (SIS) y el financiamiento para la adquisición de 600 cámaras de vigilancia con reconocimiento facial de última generación para la ciudad de Rosario. Según el Gobierno Nacional, estas herramientas contribuyen a “fortalecer y tecnificar la lucha contra el crimen organizado',
  },
  {
    img: "Salta.svg",
    title: "Provincia de Salta",
    content:
      'En abril de 2023 el Gobierno provincial y el Ministerio de Seguridad y Justicia anunciaron la instalación de 500 nuevas cámaras de seguridad, en el marco de la ampliación del Servicio de Videovigilancia del Plan de Seguridad y Justicia 2021 – 2023. Este plan contempla superar las 3.400 unidades hasta fin de año. Además, el Ministerio de Seguridad y Justicia junto con la Sociedad Anónima del Estado de Transporte Automotor (SAETA) iniciaron la implementación de un sistema de videovigilancia en las unidades del transporte de pasajeros.',
  },
  {
    img: "Cordoba.svg",
    title: "Provincia de Córdoba",
    content:
      'La unidad móvil de videovigilancia con sistema de reconocimiento facial de la Policía de Córdoba sigue operando en lugares que cuentan con grandes aglomeraciones de público, como eventos culturales o deportivos, y también en diferentes puntos estratégicos de la provincia. Hasta enero de 2020 se identificaron más de 100 individuos con este sistema, según el Ministerio de Seguridad de la provincia.',
  },
  {
    img: "corrientes.svg",
    title: "Monte Casero (Corrientes)",
    content:
      "En junio de 2023 la intendencia de Monte Casero, junto al Gobierno Provincial y el Ministerio de Seguridad, anunciaron la inauguración del Servicio Integral de Seguridad - 911. Según la autoridad local, la obra requirió una inversión de 30 millones de pesos y está dotada de cámaras con reconocimiento facial y lector de patente de vehículos automotores para realizar seguimientos eficaces en tiempo real.",
  },
  {
    img: "la-matanza.svg",
    title: "La Matanza (Provincia de Buenos Aires)",
    content:
      "Hasta julio de 2022 el gobierno local lleva adelante una fuerte inversión para desarrollar el nuevo Plan Integral de Protección Ciudadana, que consta de dotar al distrito con infraestructura de última tecnología. En ese marco, continúa la instalación de más de dos mil cámaras de seguridad, lectoras de patentes de autos en los principales ingresos, cámaras de reconocimiento facial en los grandes centros comerciales, diez mil nuevas alarmas vecinales en las distintas ciudades del distrito, entre otros elementos de prevención.",
  },
  {
    img: "mar-del-plata.svg",
    title: "Mar del Plata (Provincia de Buenos Aires)",
    content:
      "En agosto del 2022 el Consejo Deliberante aprobó la implementación en Mar del Plata del Sistema de Reconocimiento Facial de Prófugos. Sin embargo nunca fue efectivizada. La gestión de la administración local para poder acceder a la base de datos de las personas prófugas no ha presentado avances, pues no han recibido respuestas de parte de Nación. Este convenio permitiría el acceso al sistema de Consulta Nacional de Rebeldías y Capturas (Conarc), una base de datos de acceso público donde se pueden consultar los 40.132 casos de personas prófugas, con nombre y apellido, DNI, jurisdicción, juzgado interviniente y condena, entre otros datos.",
  },
  {
    img: "buenos-aires.svg",
    title: "Provincia de Buenos Aires",
    content:
      "En febrero de 2023 el Ministerio de Transporte de la Nación anunció el avance en la implementación de paradas seguras en la Provincia de Buenos Aires. En coordinación con autoridades locales se instalaron 2.126 paradas seguras en 37 municipios, las cuales cuentan con dispositivos de seguridad como pulsador antipánico y de emergencias, un intercomunicador conectado a un centro de monitoreo de seguridad, cámaras domo 360 con reconocimiento facial, baliza estroboscópica, altavoz y puertos para carga de celular.",
  },
  {
    img: "Tigre.svg",
    title: "Tigre (Provincia de Buenos Aires)",
    content:
      "Hasta septiembre de 2020 la Municipalidad de Tigre había instalado más de 60 tótems de seguridad con cámaras de reconocimiento facial. Estos permiten que los peatones puedan pulsar un botón ante situaciones de emergencia u acciones delictivas y recibir una respuesta al instante por parte del Centro de Operaciones Tigre (COT). Según la Municipalidad, este sistema de software actúa mediante cámaras fijas digitales, instaladas en las terminales de transporte público (incluida la estación fluvial) y otros puntos estratégicos del distrito.",
  },
  {
    img: "CABA.svg",
    title: "Ciudad Autónoma de Buenos Aires",
    content:
      "En abril de 2023 la Cámara en lo Contencioso Administrativo y Tributario confirmó la inconstitucionalidad del Sistema de Reconocimiento Facial de Prófugos (SRFP) mediante datos biométricos instalado por el Gobierno porteño. La resolución tiene como antecedente la acción de amparo presentada por la Asociación por los Derechos Civiles (ADC) en 2019. Según la Ciudad, el SRFP está destinado a la detección, verificación, identificación y detención de personas sobre las cuales exista una orden de captura por parte de la Justicia.",
  },
  {
    img: "vicente-lopez.svg",
    title: "Vicente López (Provincia de Buenos Aires)",
    content:
      "En diciembre de 2021 se instalaron 56 cámaras de seguridad en El Transporte del Bicentenario, línea municipal y gratuita conducida en su totalidad por mujeres. Este servicio conecta lugares estratégicos del municipio: unidades de atención primaria, centro de la tercera edad, centro de testeo COVID, entre otros. Este equipamiento consta de cuatro cámaras en cada unidad de transporte, con visualización en el tablero del conductor, permitiendo grabar cualquier hecho con la capacidad de procesar reconocimiento facial.",
  },
  {
    img: "san-isidro.svg",
    title: "San Isidro (Provincia de Buenos Aires)",
    content:
      "En mayo de 2023 se anunció que la municipalidad sumará nuevas cámaras de video vigilancia y sistema de reconocimiento facial, además de cámaras lectoras de patente, un nuevo software de análisis y búsqueda de imágenes de autos y personas que funciona como aporte para investigaciones judiciales. Según la intendencia de San Isidro, es una estrategia que contempla sumar más agentes y más tecnología para luchar contra el delito en esa localidad.",
  },
  {
    img: "tucuman.svg",
    title: "Tucumán",
    content:
      "En junio de 2021 el Sistema de Emergencias 911 de la Policía de Tucumán incorporó 500 cámaras de seguridad en la capital provincial. Según el gobierno local, estas son de última generación y cuentan con tecnología de reconocimiento facial en alta resolución. En marzo de 2023 se capacitó al personal del 911 en el uso de herramientas del nuevo software Axiom One de las cámaras, las cuales poseen 45x, infrarrojo, analítica de reconocimiento facial y de patente de vehículos.",
  },
  {
    img: "banda-del-rio.svg",
    title: "Banda del Río Sali (Tucumán)",
    content:
      "En abril de 2023 la Municipalidad de la Banda del Río Sali anunció el lanzamiento del programa Paradas Seguras. Este contempla la incorporación de dispositivos de seguridad como un pulsador antipánico y de emergencias, un intercomunicador conectado al Centro de Monitoreo Municipal, cámaras IP Domo PTZ 360° con incorporación de reconocimiento facial, baliza de alarma y altavoz. Según las autoridades locales, estas acciones tienen como objetivo brindar mayor seguridad a los usuarios que utilizan el transporte público.",
  },
  {
    img: "monteros.svg",
    title: "Monteros (Tucumán)",
    content:
      "La Municipalidad de Monteros informó en abril de 2023 la instalación de 60 nuevas cámaras de seguridad y la ampliación de un centro de monitoreo. Los nuevos equipos tienen un potente zoom, lector de patente y reconocimiento facial.",
  },
];
export default mapData;
