import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

import ZoneCard from "../ZoneCard/ZoneCard";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

import mapData from "../../../constants/map-data";
import "./zoneGallery.scss";

SwiperCore.use([Navigation, Pagination, A11y]);

const ZoneGallery = ({ setProvincia, swiper, setSwiper }) => {
  return (
    <div id="zoneGallery">
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        initialSlide={0}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
        onSlideChange={(e) => {
          if (e.activeIndex === 0) {
            setProvincia("mendoza");
          }
          if (e.activeIndex === 1) {
            setProvincia("santaFe");
          }
          if (e.activeIndex === 2) {
            setProvincia("salta");
          }
          if (e.activeIndex === 3) {
            setProvincia("cordoba");
          }
          if (e.activeIndex === 4) {
            setProvincia("corrientes");
          }
          if (e.activeIndex === 5) {
            setProvincia("laMatanza");
          }
          if (e.activeIndex === 6) {
            setProvincia("marDelPlata");
          }
          if (e.activeIndex === 7) {
            setProvincia("BuenosAires");
          }
          if (e.activeIndex === 8) {
            setProvincia("tigre");
          }
          if (e.activeIndex === 9) {
            setProvincia("caba");
          }
          if (e.activeIndex === 10) {
            setProvincia("vicenteLopez");
          }
          if (e.activeIndex === 11) {
            setProvincia("sanIsidro");
          }
          if (e.activeIndex === 12) {
            setProvincia("tucuman");
          }
          if (e.activeIndex === 13) {
            setProvincia("bandaDelRio");
          }
          if (e.activeIndex === 14) {
            setProvincia("monteros");
          }
        }}
      >
        {mapData.map((e, index) => {
          return (
            <SwiperSlide>
              <ZoneCard
                img={require("../../../assets/img/maps/" + e.img)}
                title={e.title}
                content={e.content}
                key={`key_${index}.${e.content.title}`}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ZoneGallery;
