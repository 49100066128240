import * as React from "react"

const MapComp = ({
  width = '355',
  height = '778',
  setProvincia,
  provincia,
  handleChangeSlide,
  handleChangeSlide2,
  setDisplay,
  display
}) => {

  const color = '#ffffff'

  return (
    <svg
      viewBox="0 0 355.24 777.63"
      width={width}
      height={height}
    // {...props}
    >
      <defs>
        <style>
          {`
        .cls-1{fill:#c9ced3} 

        .active{
          fill: ${color}; 
          transition: all 0.3s ease-in-out;
        }
        
        .mendoza{
          fill: ${provincia === 'mendoza' ? '#212529' : color}
        }
        .mendoza:hover{
          cursor: pointer;
        }
        .santaFe{
          fill: ${provincia === 'santaFe' ? '#212529' : color}
        }
        .santaFe:hover{
          cursor: pointer;
        }
        .salta{
          fill: ${provincia === 'salta' ? '#212529' : color}
        }
        .salta:hover{
          cursor: pointer;
        }
        .tucuman{
          fill: ${provincia === 'tucuman' ? '#212529' : color}
        }
        .tucuman:hover{
          cursor: pointer;
        }
        .cordoba{
          fill: ${provincia === 'cordoba' ? '#212529' : color}
        }
        .cordoba:hover{
          cursor: pointer;
        }
        .laMatanza{
          fill: ${provincia === 'laMatanza' ? '#212529' : color}
        }
        .laMatanza:hover{
          cursor: pointer;
        }
        .marDelPlata{
          fill: ${provincia === 'marDelPlata' ? '#212529' : color}
        }
        .marDelPlata:hover{
          cursor: pointer;
        }
        .tigre{
          fill: ${provincia === 'tigre' ? '#212529' : color}
        }
        .tigre:hover{
          cursor: pointer;
        }
        .caba{
          fill: ${provincia === 'caba' ? '#212529' : color}
        }
        .caba:hover{
          cursor: pointer;
        }
        .corrientes{
          fill: ${provincia === 'corrientes' ? '#212529' : color}
        }
        .corrientes:hover{
          cursor: pointer;
        }
        .BuenosAires{
          fill: ${provincia === 'BuenosAires' ? '#212529' : color}
        }
        .BuenosAires:hover{
          cursor: pointer;
        }
        .vicenteLopez{
          fill: ${provincia === 'vicenteLopez' ? '#212529' : color}
        }
        .vicenteLopez:hover{
          cursor: pointer;
        }
        .sanIsidro{
          fill: ${provincia === 'sanIsidro' ? '#212529' : color}
        }
        .sanIsidro:hover{
          cursor: pointer;
        }
        .bandaDelRio{
          fill: ${provincia === 'bandaDelRio' ? '#212529' : color}
        }
        .bandaDelRio:hover{
          cursor: pointer;
        }
        .monteros{
          fill: ${provincia === 'monteros' ? '#212529' : color}
        }
        .monteros:hover{
          cursor: pointer;
        }
        @media (max-width: 991px) {
          svg {
            width: 319px;
          }
        }
        `}
        </style>
      </defs>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">

        <path className="cls-1" d="M252.5,313.8L252.5,313.8l0.2,0l-3.5-3.6c0.1,0.2,0.2,0.4,0.3,0.6L252.5,313.8z"/>
<path onClick={() => { handleChangeSlide(0); handleChangeSlide2(0); setProvincia('mendoza'); setDisplay(!display) }} className="active mendoza" d="M32.1,358.1c0.8,0.7,1.8,1.3,2.9,1.6c0.7,0.1,1.4,0,2.1-0.1c0.8-0.1,1.6-0.1,2.4,0c0.5,0.1,0.9,0.3,1.3,0.6
	c0.2,0.1,0.5,0.3,0.7,0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.6-0.3,1.4-0.5,2.1-0.4c0.6,0.2,1.2,0.8,2,3.5c0.4,0.5,0.9,0.8,1.5,0.9
	c0.1,0,0.5,0.1,0.8,0.1c0.9-0.1,1.8,0.1,2.7,0.4c0.4,0.2,0.8,0.5,1.1,0.9l0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.8-0.5,1.5-1.1,2.3-0.7
	c0.2,0.1,0.5,0.4,0.9,0.7c0.5,0.5,1.1,0.9,1.7,1.3c0.8,0.3,1.6,0.6,2.5,0.7c-1-16-2.1-33.8-2.1-34.1c0-0.2,0.1-0.5,0.2-0.7
	c0.2-0.2,0.4-0.3,0.7-0.4l30.1-1.3c-0.1-3-0.1-7.4-0.1-7.9c0-0.8,0.2-1.6,0.4-2.3c0.2-0.6,0.3-1.3,0.4-2c0-1.2-0.2-2.3-0.4-3.5
	c-0.1-0.7-0.1-2.7-0.1-4.8v-1c0-0.6,0-1.1,0.1-1.7c0.1-0.9,0.1-2.5,0.1-2.9c-0.2-0.5-1.7-2.1-2.1-2.7c-0.1-0.2-0.3-0.4-0.5-0.6
	c-0.3-0.4-0.4-1-0.4-3.2v-0.1c-0.1-0.2-0.3-0.9-0.5-1.4c-0.2-0.4-0.2-0.8-0.2-1.2c0-0.1,0-0.4-0.1-0.5c-0.1-0.1-0.7-0.7-0.8-0.9
	c-0.8-0.6-0.9-2.7-0.5-3.6c0.2-0.4,0.4-0.7,0.7-1.1c0.1-0.2,0.2-0.4,0.4-0.5c-0.1-0.1-0.1-0.4-0.2-0.5c-0.5-0.9-0.7-2-0.5-3.1
	c0-0.7-0.1-1.4-0.1-1.8c-0.3-0.4-0.7-0.7-1.2-1c-0.2-0.1-0.3-0.2-0.4-0.2c-0.6-0.6-1.2-1.3-1.7-2c-0.2-0.2-0.4-0.3-0.6-0.4
	c-0.3-0.2-0.5-0.3-0.8-0.5c-0.4-0.5-0.7-1.1-0.7-1.8c0-0.1-0.1-0.3-0.1-0.4s-0.3-0.2-0.5-0.4c-0.2-0.2-0.3-0.2-0.4-0.3
	c-0.7-0.7-1.3-1.5-1.8-2.4c-0.1-0.3-0.2-0.8-0.4-1.7c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.2-0.1-0.4-0.3-0.5c-0.4-0.5-0.7-1.1-0.8-1.8
	c-0.2-1.2-0.1-2.5,0.1-3.7c0.1-0.6,0.2-1.1,0.2-1.7c-0.1-1.5-0.2-3-0.5-4.5c-0.1-0.3-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.1-1.6v-1
	c0-0.4-0.2-0.8-0.4-1.1c-0.1-0.1-0.2-0.2-0.2-0.4c-0.2-0.7-0.3-1.5-0.2-2.3c0-0.2,0-0.4,0.1-0.6c-0.6-1-1.3-1.8-2.1-2.6l-0.1-0.1
	c-0.7-0.1-1.4-0.2-2.1-0.2c-0.4-0.1-0.8-0.3-1.2-0.5c-1-0.6-0.9-1.9-0.8-2.6c-0.1-0.1-0.4-0.3-0.5-0.4l-0.5-0.1
	c-0.1,0-0.1,0-0.4,0.1c-0.8,0.2-1.7,0.3-2.5,0.3c-1.7-0.1-3.3-0.7-4.6-1.8c-0.1-0.1-0.2-0.2-0.4-0.2c-1.7,0-3.3,0.1-5,0.4
	c-0.1,0.2-0.4,0.5-0.5,0.7c-0.2,0.4-0.4,0.7-0.7,1c-1.2,1.2-2.8,1.9-4.5,1.9c-1.3,0-2.7-0.3-3.8-1c-0.5-0.5-0.8-1.3-0.7-2
	c-0.2,0.2-0.4,0.3-0.7,0.4c-0.8,0.2-1.5-0.4-2.8-2c-0.2,0.1-0.3,0.2-0.5,0.2c-0.7,0.5-1.5,0.8-2.3,1.1c-1.7,0-3.3,0-5-0.2
	c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0.9-0.5,1.7-1.2,2.1c-0.5,0.4-1.1,0.5-1.7,0.5c-0.2,0-0.5,0-0.7,0.1c-0.1,0.7,0,1.4,0.2,2.1
	c0.3,1,0.2,2-0.1,3c-0.2,1-1.1,1.7-2.1,1.7c-0.1,0.1-0.2,0.2-0.2,0.4c-0.4,1-1.5,1.6-2.6,1.4c-0.6-0.2-1-0.5-1.4-1
	c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.5,0.1,0.7c0,1.5-2.2,2.1-3,2.3c-0.8,0.2-1.6,0.1-2.3-0.2c0.2,1.7,0.5,3.3,1.1,4.9
	c1.1,3,2.6,5.6,3,8.9c0.2,1.4,0.9,4,2.6,3.2c1.2-0.5,1.4-2.4,2.9-1.8c1.1,0.4,0.6,2.5-0.2,3.2c-1.2,0.8-1.8,2.2-1.7,3.6
	c0.1,1.9,0.5,3.8-0.1,5.8c0,0.5,0.1,1,0.5,1.4c1.3,1.6,1,3.2,0.1,4.8c-1.8,3.7-3.6,7.4-5.5,11.1c-0.2,0.5-0.5,1-0.9,1.5
	c-1.1,1.3-1.5,3-1.1,4.6c0.6,2.8-0.2,5.7-2.2,7.7c-1.8,2-1.7,3.3,0.5,5.1c0.9,0.7,1.5,1.8,1.7,2.9c0.2,1.1,0.2,2.2,0.1,3.3
	c-0.6,2.4-0.2,5,1,7.2c0.5,0.9,0.7,2,0.8,3c0.1,0.5,0,1-0.4,1.4c0.4,0.8,0.8,1.7,1.1,2.6c0.1,0.5,0,0.9-0.1,1.4
	c-0.1,0.3-0.1,0.6-0.1,0.9c0.4,0.1,0.7,0,1.1-0.1c1.6-0.8,2.7-0.8,3.2,0.1c0.3,0.5,0.4,1,0.3,1.6c0,0.3,0.1,0.8,0.1,1
	c0.7,1,1.6,1.9,2.6,2.6c0.1,0.1,0.2,0.2,0.4,0.2c0.9,0.4,1.7,1.1,2.3,1.9c0.2,0.4,0.4,0.7,0.7,1c0.6,0.7,1,1.5,1.2,2.4v0.2
	C31.8,356.3,31.9,357.2,32.1,358.1z"/>
<path onClick={() => { handleChangeSlide(1);  handleChangeSlide2(1); setProvincia('santaFe'); setDisplay(!display) }} className="active santaFe" d="M237.8,148.1c0.1-0.1,0.3-0.2,0.4-0.4c0.4-0.5,0.8-1.1,1-1.8c0-0.1-10-0.1-9.9-0.1l-45.6-1.1l-6.8,49.6l-0.7,4.6l5.2,6.7
	c0.2,0.2,0.2,0.5,0.2,0.8c-0.1,0.5-3.1,13.7-3.6,15.2c-0.3,1-0.8,2.1-1.2,3.1c-0.2,0.4-0.4,0.9-0.5,1.3v0.8c0.1,0.9,0,1.8-0.3,2.6
	c-0.4,0.7-1,1.3-1.7,1.7c-0.2,0.2-0.6,0.4-0.7,0.5c-0.1,1.3,0,2.5,0.2,3.8c0.1,0.1,0.2,0.4,0.4,0.5c0.7,0.7,1.1,1.6,1.1,2.6
	c-0.1,0.5-0.2,1.1-0.4,1.6c-0.2,0.5-0.3,0.9-0.4,1.4c0.3,1,0.8,1.9,1.4,2.7c0.2,0.1,0.4,0.3,0.7,0.5c0.9,0.6,1.6,1.2,2.3,2
	c0.4,0.7,4.3,10.5,3.9,12.5c-0.3,1.5-4.3,7.7-5.5,9.6l-13.9,22.8l20.1,0.3c15-17.7,15.1-17.9,15.3-17.9c0.5-0.4,1.1-0.5,1.7-0.5h0.5
	c0.7-0.1,1.3-0.1,2,0c0.2,0,0.6,0.1,3,1.3l4-5.4c-0.4-0.3-0.8-0.6-1.1-1c-0.4-0.4-0.8-0.7-0.9-0.8c-0.9-0.8-1.7-1.7-2.4-2.7
	c-0.2-0.3-0.5-0.7-0.7-1c-1.3-1.9-2.3-4-3.1-6.2c-0.2-0.5-0.5-1.1-0.7-1.5c-0.7-2-1.1-4-1.1-6.1c0-0.2-0.1-0.5-0.1-0.7
	c-0.1-1.3,1.7-5.8,1.8-6.3c0.1-1.3,0-2.6-0.2-3.9c-0.1-0.7-0.4-1.3-0.7-1.9c-0.2-0.5-0.4-1-0.5-1.5c-0.1-1.5,0.2-3,0.8-4.4
	c0.1-0.3,0.2-0.6,0.2-0.9c0.2-1.2,1.3-2.1,2.6-2.1c0.8,0,1.5,0.3,2.1,0.7c0.4,0.3,0.9,0.5,1.4,0.6c0.9,0,1.5-0.2,2.7-1.5
	c1.2-1.3,5-8.3,5.8-9.7c1.2-1.9,2.5-3.8,4-5.5c1.4-1.7,2.6-3.6,3.4-5.7c0.1-0.7,0.6-10.2,0.6-10.3c-0.1-0.7,0.3-1.5,1-3.5
	c0.2-0.6,0.5-1.4,0.6-1.7c-0.1-0.3-0.3-0.6-0.5-0.9c-0.4-0.5-0.6-1.2-0.7-1.8c0.2-1.2,0.6-2.3,1.1-3.4c0.4-1.1,1-2.7,1.1-3.2
	c-0.1-0.7-0.4-1.4-0.7-2c-0.3-0.7-0.6-1.5-0.7-2.3c0.4-1.9,1-3.7,1.9-5.4c0.2-0.4,0.8-0.8,2.4-1.7c0.6-0.3,1.1-0.7,1.6-1.1
	c0.7-1.9,1.3-3.8,1.8-5.7c0.1-0.6,0.3-1.2,0.6-1.8c0.2-0.5,0.7-0.9,1.9-1.8c0.4-0.3,0.8-0.6,1.1-1c-0.1-2.5-0.3-5.1-0.6-7.6
	c-0.1-1.1-0.1-1.3-0.1-1.4C235.7,150,236.2,149.3,237.8,148.1z"/>
<path onClick={() => { handleChangeSlide(2);  handleChangeSlide2(2);  setProvincia('salta'); setDisplay(!display) }} className="active salta" d="M37.9,75.7c1.3,0.5,2.3,1.5,2.6,2.9c0,0.3,0,0.5-0.1,0.8c1.5,1.9,3.6,3.1,6,3.4c4.6,0.4,32.8-1.1,33.1-1.1
	c0.3,0,0.7,0.1,0.9,0.4c0.2,0.3,0.2,0.7,0.1,1c-0.2,0.7-0.4,1.8-0.1,2c0.3,0.2,0.5,0.2,0.7,0.3c0.7,0.1,1.3,0.7,1.5,1.4
	c0.7,1.9,0.2,4-1.2,5.4c-1,0.9-2.3,1.4-3.6,1.5c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0.6-0.3,1.2-0.1,1.8c0.3,0.9,0.8,1.7,1.4,2.4
	c0.9,0.7,1.6,1.7,1.9,2.9c0.1,0.2,0.1,0.5,0.2,0.7c0.2,0.5,0.5,1.8,0.7,2.1c0.5,0.5,1.8,1.7,2.7,1.6c1-0.1,1.9-0.6,2.6-1.3l0.1-0.2
	c0.6-1,1.1-1.7,1.8-1.8c0.8-0.3,1.7-0.3,2.5,0.1c0.1,0.1,0.2,0.2,0.4,0.3c1,0.8,2.1,1.5,3.2,2.1c1,0.6,2.1,0.9,3.2,0.8h0.4v-0.2
	c0-0.5-0.1-1-0.3-1.4c-0.2-0.5-0.3-0.9-0.3-1.4c0-2,2.7-2,3.7-2.1h0.8c1.8-0.1,3.7-0.1,5.5,0.1c1.5,0,3,0.9,3.6,2.3
	c0.2,0.5,0.8,0.9,1.4,0.8c0.2,0,0.5-0.1,0.7-0.2c0.5-0.3,1.1-0.3,1.7-0.2c0.5,0.1,0.9,0.4,1.3,0.7c0.5,0.4,0.8,0.6,1.1,0.5
	c0.3-0.1,0.2-0.1,0.5-0.6c0.2-0.6,0.7-1.1,1.3-1.2c0.2-0.1,0.5,0,0.9,0c0.7,0.1,1.4,0,2.1-0.2c0.6-0.6,1-1.4,1.2-2.2
	c1.4-3.1,4-9,5.8-13.2c0.1-0.2,0.3-0.5,0.6-0.5c0.3-0.1,0.6-0.1,0.8,0.1l4.7,2.3l8.7-0.1h1.8L168.1,61l-0.6-48.5
	c-1.1-0.3-2.1-0.3-2.7-0.8c-2.7-2.3-5.7-4.3-5.8-8.4c0-0.1-0.2-0.2-0.3-0.5l-24.3,0.3c0.8,2.9-2.6,3.9-2.7,6.4
	c0,0.2-0.6,0.4-0.9,0.6c-1.1,0.5-1.7,1.8-1.5,3c0.2,1.3-0.1,2.7-0.7,3.9c-0.5,1.3-0.9,2.6-1.4,3.8c-0.2,0.4-0.5,0.8-0.8,1.3
	c-1.2-0.8-1.1-1.8-0.8-2.9c0.7-2.3-0.4-4.8-2.6-5.9c-1-0.5-1.5-1.5-1.4-2.6c0-0.7-0.2-1.4-0.7-2c-1.1-1.3-2.3-2.5-3.6-3.6h-2
	c-3,0.1-5.9,0.1-8.9,0.1c0.1,1.7,0.2,3.5,0.1,5.2c-0.2,1.4-0.8,2.7-1.6,3.9c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,1.1,0.7,7.6,1.3,8.8
	c0.5,1,1.3,1.1,2.7,1.2c2,0.1,3.4,1.2,3.6,2.9c0,0.1,0,0.3,0.1,0.4c0.2,0.9,0.1,1.9-0.4,2.7c-0.2,0.3-0.4,0.5-0.7,0.7
	c-0.3,0.2-0.3,0.2-0.3,0.4s0.6,0.8,1.4,1.3c0.9,0.4,1.6,1.3,1.6,2.3c0.1,3,0.4,4.6,0.7,5.1c0.2,0,0.2-0.1,0.3-0.1s0.1-0.2,0.1-1
	c-0.1-1.8,0.9-2.7,2.7-2.7s2.6,1.4,3.3,2.6c0.6,0.9,1,1.5,1.4,1.5c0.7-0.1,1.4-0.5,1.8-1.1c0.6-0.8,1.4-1.3,2.4-1.4
	c1.6,0.1,3.2,0.5,4.6,1.3c0.3,0.2,0.5,0.5,0.5,0.8l0.8,17c0,0.6-0.4,1-1,1c-1.1,0.1-2.1,1.5-2.8,2.5c-0.3,0.4-0.6,0.8-1,1.2
	c-0.9,0.7-2.1,1-3.2,0.6c-0.6-0.2-1.1-0.2-1.7,0c-0.1,0.5-0.1,1-0.1,1.5c0,0.5,0,1-0.1,1.5c-0.2,1-0.6,2.2-2.4,2.2
	c-1.4-0.1-2.7-1-3.2-2.3c-0.1-0.5-0.4-0.9-0.8-1.2h-0.3c-0.2,0.2-0.3,0.4-0.4,0.7c-0.9,1.9-2.6,1.9-3.8,1.9h-0.2
	c-1.3,0.1-2.5-0.9-2.6-2.3c0-0.1,0-0.2,0-0.2c0-0.2-0.1-0.3-0.3-0.4l-0.4,0.4c-0.4,0.4-0.8,1-1.6,1c-1.2-0.2-2.2-0.7-3.1-1.5
	c-0.5-0.4-1.1-0.6-1.7-0.7c-0.2,0-0.4,0.1-0.5,0.2c-0.5,0.3-1,0.4-1.6,0.3c-0.8-0.1-1.5-0.6-2-1.2c-0.4-0.5-0.7-1.2-0.7-1.9
	c0.1-0.5-0.1-1-0.4-1.4c-0.7-0.7-1-1.6-0.9-2.6c0-0.2-0.1-0.4-0.3-0.5c-0.7-0.4-1.2-1-1.4-1.8c-0.2-0.5-0.1-1.1,0.1-1.6
	c0.1-0.4,0.2-0.7,0.1-1.1c-0.2-0.5-0.5-0.9-0.8-1.3c-0.1,0-0.3,0.1-0.4,0.1c-0.4,0.1-0.8,0.1-1.2,0.1c-1-0.4-1.8-1.1-2.4-2
	c-0.2-0.4-0.2-0.9-0.2-1.4c0-0.3,0-0.5-0.1-0.8c-0.3-0.3-0.6-0.5-0.9-0.7c-0.5-0.3-1-0.6-1.4-1c-0.8-0.8-0.7-2.4-0.5-4.3
	c0.1-0.7,0.2-1.3,0.1-2c-0.7-0.6-1.6-1.2-2.5-1.5c-0.5-0.2-0.9-0.5-1.3-0.7c-1-0.7-2.2-0.8-3.3-0.3c-0.8,0.3-0.9,1.5-1,2.9
	c0,0.3,0,0.5-0.1,0.8c0.2,0.2,0.8,0.7,1.2,1c0.8,0.4,1.4,1,1.8,1.8c0.5,1.4-0.2,4.6-0.7,5.5c0,0.8,0,1.6,0.2,2.4
	c0.2,1.8,0.4,2.9-0.1,3.5c-0.6,0.7-1.5,0.9-2.3,0.6c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.3-0.1,0.4v0.2c-0.1,1.2-1.2,2.2-2.4,2.3
	c-1.3,0.3-2.6-0.5-2.9-1.8h-0.6c-1,0-2-0.5-2.8-1.2c-0.6-0.5-0.9-1.2-0.8-2c0-0.2-0.1-0.6-0.1-0.7s-0.1-0.2-0.7-0.2
	c-0.3-0.1-0.6-0.2-0.9-0.2c-0.9-0.3-1.7-0.8-2.4-1.5c-0.5-0.5-1-0.9-1.6-1.3c-0.4-0.2-0.7-0.5-0.9-0.8c-0.2,0.9-0.4,1.7-0.5,2.6
	c-0.5,2.6-1.7,3.9-4.2,4.9c-4.4,1.8-8.7,3.8-12.9,6c-4.5,2.4-7,6.5-7.8,11.6C36.2,74.6,36.4,75.1,37.9,75.7z"/>
<path onClick={() => { handleChangeSlide(3);  handleChangeSlide2(3);  setProvincia('cordoba'); setDisplay(!display) }} className="active cordoba" d="M175.7,267.7c2.4-3.6,5-8,5.2-8.9c0.1-1.3-3-9.9-3.6-11c-0.5-0.6-1.1-1-1.7-1.4c-0.2-0.2-0.5-0.4-0.8-0.5
	c-1.1-0.8-2-3.3-2.1-3.8c-0.1-0.9,0-1.7,0.4-2.5c0.1-0.4,0.3-0.9,0.4-1.2c-0.1-0.4-0.4-0.8-0.7-1.1c-0.2-0.3-0.4-0.6-0.6-0.9
	c-0.5-1-0.5-4.5-0.4-5.2c0.1-0.7,0.8-1.1,1.5-1.7c0.4-0.2,0.7-0.5,1-0.8c0.1-0.6,0.1-1.2,0.1-1.8c0-0.4,0-0.9,0.1-1.3
	c0.2-0.6,0.4-1.2,0.7-1.7c0.4-0.9,0.8-2,1.1-2.9c0.4-1.4,2.9-12.3,3.5-14.7l-5.2-6.7c-0.2-0.2-0.2-0.5-0.2-0.8l0.6-4.4l-4.6-7.6
	l-19.3,0.4c-0.3,0-0.6-0.1-0.8-0.4l-2-2.3c-1.7,0.1-7.1,0.1-8.7,0.2c-2.3,0-4.5-1.8-5.2-2.4c-0.7-0.7-1.2-1.5-1.7-2.4
	c-0.4-0.6-0.8-1.2-1.2-1.8c-2.4,0-4.9,0.1-7.3,0.4c-1.1,0.1-2.1,0.2-2.7,0.2c-0.7,0.1-1.5,0.2-2.2,0.4c-0.9,0.3-1.7,0.8-2.3,1.5
	c-0.4,0.7-0.5,3-0.5,4.5c0,1.1-0.1,2.1-0.3,3.2c-0.4,1.5-2.3,3-4.3,4.6l-0.1,0.1c-0.4,0.4-1.1,0.8-1.7,1.3c-0.2,0.2-3.5,2.7-3.6,2.9
	c-0.3,1.1-0.4,2.3-0.4,3.5c-0.1,1.3-0.2,2.3-0.3,2.9c-0.5,1.5-1.2,2.9-2.1,4.2c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.6,0,1.2-0.1,1.8
	c-0.4,1.3-1.1,2.5-1.9,3.6c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,1.7,0.5,12.5,1,20.7c0.8,0.1,2.4,0.7,6.3,3.1c0.8,0.5,4.9,3,5.3,3.9
	c0.3,0.8,0.2,1.6-0.1,2.4c-0.1,0.2-0.1,0.3-0.1,0.5c0.2,0.3,0.4,0.5,0.7,0.7c0.4,0.1,0.8,0.1,1.2,0l0.1-0.1c0.3-0.4,0.7-0.8,1.2-0.9
	c0.6-0.1,1.1,0.1,1.5,0.5c0.8,0.8,1.2,1.9,1.3,3c0.2,5.6-0.2,11.1-1.2,16.6c-0.4,1.3-1,2.5-1.9,3.6l1.5,38.3l32.2-0.4l-0.2-14.4
	c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3l9.7,0.1L175.7,267.7z"/>
<path className="cls-1" d="M7.8,236.7c1,0.4,1.6,1.5,1.2,2.6c0,0.1-0.1,0.2-0.1,0.2c-0.5,1.3,0.2,1.8,1.2,2.2s1.8,0.7,2.7,1.1
	c-0.1,0.6-0.2,1.1-0.4,1.7c-0.9,1.6-0.8,3.6,0.1,5.2c0.6,1.4,1.4,2.6,2.4,3.8c0.7,0.8,1.2,1.6,1.5,2.6c0.7,0.2,1.4,0.3,2.1,0.3
	c0.5-0.1,1-0.3,1.4-0.5v-0.2c-0.1-0.7-0.3-1.6,0.4-2.2c0.9-0.8,1.7-0.9,2.4-0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.3,0.5,0.5,0.8,0.6
	c0.1,0,0.1,0,0.2-0.1c0.2-0.9,1-1.7,2-1.8h0.3c0.1-0.1,0.1-0.2,0.1-0.3c0.2-0.6,0.3-1.2,0.1-1.8c-0.7-2.3-0.4-3.9,0.8-4.5
	c0.5-0.2,1.1-0.3,1.6-0.3c0.4,0,0.4,0,0.5-0.1c0.3-0.2,0.3-0.3,0.3-0.5c0-0.6,0.3-1.2,0.8-1.6c0.4-0.4,1-0.5,1.5-0.5
	c1.4,0.2,2.9,0.3,4.3,0.3c0.6-0.3,1.2-0.6,1.7-0.9c1.2-0.7,1.7-0.9,2.2-0.6c0.2,0.1,0.3,0.2,0.7,0.7c0.3,0.4,0.6,0.7,0.9,1.1
	c0.4-0.5,0.9-1.1,1.9-0.7c0.8,0.5,1.3,1.4,1.2,2.4v0.8c0.8,0.3,1.6,0.5,2.4,0.5c1.1,0,2.1-0.4,2.9-1.2c0.1-0.3,0.3-0.5,0.5-0.7
	c0.5-0.8,0.9-1.4,1.7-1.6c0.1,0,5.4-0.8,6.5-0.2c0.3,0.1,0.5,0.3,0.7,0.5c0.8,0.5,2,1.5,3.4,1.4c0.7,0,1.3,0,2-0.2
	c0.4-0.1,0.9-0.2,1.3-0.1l0.7,0.2c0.1,0,0.2,0.1,0.3,0.1c1.7,1,1.7,1.7,1.7,2v0.4c0,0.2-0.1,0.6,0,0.8c0.2,0.1,0.5,0.1,0.7,0.1h0.5
	c0.3-0.6,0.6-1.3,0.7-2c0-0.1,0-0.1,0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.7,0.1-1.4,0.5-1.9
	c0.2-0.4,0.5-0.7,0.6-1c0.1-0.5,0.1-0.9,0-1.4c-0.1-0.3-0.1-0.5-0.1-0.8c0-1.1,0.9-2,2-2.1c0.5-0.1,5.9-0.5,9.9-0.8
	c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2-0.5-0.4-0.9-0.5-1.2c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.4-0.1-0.6-0.2c-1.1-0.2-2.2-0.7-3-1.5
	c-1-1.7-1.6-3.6-1.8-5.5c-0.1-1.6-0.5-5.7-1.3-7.4c-1.5-3.5-3.4-6.7-5.9-9.5c-1.5-1.6-2.8-3.4-4-5.2c-0.3-0.6-0.6-1.1-1.1-1.5
	c-0.4-0.4-3.1-2.1-5.2-3.4c-2.6-1.7-5-3.2-5.9-3.9c-0.4-0.4-1.1-0.9-1.8-1.5c-1.2-1.1-3.4-3-4-3.4c-0.4,0.2-0.8,0.3-1.2,0.4
	c-0.7,0.4-1.5,0.6-2.3,0.6h-0.7c-0.6,0.1-1.2-0.1-1.7-0.4c-0.2-0.1-0.3-0.4-0.6-0.7c-0.2-0.4-0.6-0.8-0.9-1.1
	c-1.5-0.3-3.1-0.4-4.6-0.4c-0.2,1.7-1.4,1.7-1.8,1.7c-2.2,0-3.7-4.3-3.9-5.8c-0.1-0.8,0.1-1.6,0.3-2.3c0.1-0.1,0.1-0.3,0.1-0.4
	c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.9,0.4-1.8,1.1-2.4c0.5-0.4,0.9-1,0.9-1.7c-0.1-4-0.5-5.1-0.7-5.4
	c-0.3-0.4-0.6-0.8-1-1.1c-0.3-0.2-0.7-0.5-0.9-0.8c-0.3-0.4-0.6-0.9-0.9-1.4c-0.1-0.2-0.3-0.5-0.5-0.7c-0.1-0.1-0.5-0.2-0.8-0.4
	c-0.8-0.2-1.5-0.7-2.1-1.3c-0.3-0.4-0.5-0.8-0.7-1.3c-0.4-1.1-0.7-1.6-1.5-1.7c-0.3,0-0.6,0.1-0.9,0.2c-0.6,0.3-1.2,0.4-1.9,0.4
	c-1,0-1.9-0.2-2.8-0.6c-0.1,0.2-0.1,0.5-0.2,0.8c-0.3,1.8-0.1,3.6,0.8,5.2c1.6,3,1.2,6-0.1,8.9c-0.4,1-0.9,2-1.5,3
	c-1.2,1.8-1.4,4.1-0.6,6.1c0.9,2.4,1.7,4.8,2.6,7.2c0.6,1.2,0.6,2.6-0.1,3.7c-0.8,1.1-0.8,2.5-0.2,3.6c0.5,1.1,0.1,2.4-1,3
	c-0.3,0.1-0.5,0.2-0.8,0.2c-1.5,0.2-2.7,1.2-3.1,2.6c-1.4,2.7-2,5.7-1.9,8.8c0.2,1.3,0,2.6-0.7,3.7c-0.5,0.8-0.7,1.7-0.3,2.6
	c0.1,0.5,0.2,1.1,0.2,1.6c-0.4,0.2-0.7,0.5-1,0.5c-2,0.4-2.6,1.8-2.9,3.6c-0.2,1.5-0.4,3-0.7,4.5C5.2,233.8,5.6,235.7,7.8,236.7z"/>
<path className="cls-1" d="M134.4,490.8c-1.2,0.4-2.6,0.2-3.7-0.4c-2-1.1-3.8-2.3-5.9-3.7c0.8-0.6,1.2-1.3,1.9-1.4c1.5-0.3,2.8-1.1,3.7-2.3
	c0.4-0.4,0.9-0.7,1.5-0.8c3.7-0.9,6.4,1.4,6.2,5.2c-0.2,1.5,0.9,2.8,2.4,3c0.4,0,0.7,0,1.1-0.1c1.6-0.5,3.1-1,4.7-1.5
	c1.9-0.5,3.2-2.2,3.3-4.1c0.1-1.7,0.2-3.5,0.2-5.2c-0.1-1.3-0.4-2.6-0.7-3.8c-0.2-0.9-0.4-1.7-0.5-2.6c-0.1-1.7-1.2-2.7-2.8-2.1
	c-0.7,0.2-1.3,0.7-1.7,1.3c-0.8,1.6-2,1.8-3.6,1.5c-0.9-0.2-1.8-0.2-2.7-0.2c0.4,2.6,2.7,1.7,3.9,2.6c0.4,1.1,0.1,2.3-0.9,2.4
	c-2.1,0.4-4.3,0.6-6.4,0.5c-1.2-0.1-1.4-1.1-0.6-2c0.3-0.4,0.7-0.5,1-0.9c0.6-0.8,0.4-1.5-0.5-1.7c-0.9-0.2-1.9-0.1-2.8,0.1
	c-1.8,0.6-3.8,0.3-5.3-1c-1-0.7-1.5-1.3-1.5-2.1c-14.2,0.4-54.6,1.7-78.9,3.8c-12,1.1-29,2.8-36.2,3.6c0,0.7-0.3,1.4-0.7,1.9H7.6
	c-1.6-0.1-2.9,1.1-3.1,2.7c-0.3,1.1-0.1,2.3,0.5,3.3c1.2,2,0.8,3-1.4,4c-0.2,0.1-0.4,0.2-0.5,0.4c0,2.4,0.1,4.7,0.1,7
	c0,1.2,0.7,1.7,1.8,2c1.3,0.3,2.7,0.3,3.9,1.4c-0.4,0.8-0.8,1.7-1.2,2.4c-1,1.2-1,2.9-0.2,4.2c0.8,1.4,1.7,2.6,2.8,3.8
	c1.3,1.3,2.2,3.1,2.3,5c0.1,1.6-0.1,3.3-0.8,4.8c-0.4,0.9-0.5,1.9-0.3,2.8c0.6,1.5,0.5,3.1-0.3,4.5c-0.3,0.6-0.5,1.3-0.8,1.9
	c-0.5,1.3,0.4,2,1.1,2.6c0.6,0.6,1.7,0.6,2.3,0c0,0,0.1-0.1,0.1-0.1c0.7-0.5,1.3-1.2,2-1.8c1.4,1,2.7,1.8,4.4,0.7
	c0.7-0.1,1.4,0.1,2,0.5c0.4,0.3,0.4,1.3,0.2,1.8c-0.5,1.1-1,2.2-1.6,3.2c-0.6-0.2-1.2-0.4-1.9-0.5c-0.5-0.1-1-0.1-1.5,0.2
	c-1.7,1.3-3.8,1.8-5.8,1.4c-1.1-0.2-2.1,0.4-2.4,1.5c0,0,0,0,0,0c-0.5,0.9-0.2,2,0.7,2.6c0.9,0.6,1.5,1.6,1.7,2.6
	c0.4,1.7,0.5,1.8,2.4,1.6c3-0.4,5.2,1.1,7.1,3.2c0.8,0.8,1,1.9,0.5,2.9c-0.5,1.2-1.5,2.3-2.7,2.9c-1.6,0.8-2.6,2.3-2.8,4.1
	c-0.3,1.6,0.2,3.3,1.2,4.6c0.5,0.6,0.8,1.3,0.9,2.1c5.5-0.4,18.4-1.3,31.2-2c14.5-0.8,32.4-1.7,37.2-1.9c0.2-0.7,0.5-1.4,0.9-2.1
	c1.8-2.6,3.3-5.4,4.6-8.3c0.8-2.2,2.9-3.4,3.7-5.5c0.1-0.3,0.8-0.4,1.2-0.5c0.7,0,1.3,0,2,0.2c2,0.3,2.3,0.2,3.2-1.6
	c0.2-0.3,0.4-0.7,0.6-1c0.7,0.1,1.3,0.1,2.4,0.2c-0.8-0.9-1.3-1.5-1.6-1.9c0.9-0.9,1.7-1.6,2.5-2.4c0.2-0.2,0.4-0.4,0.5-0.7
	c1.3-1.4,2.7-1.2,3.6,0.6c0.1,0.3,0.5,0.5,0.9,1c2.1-3.6,3.7-0.1,5.6,0.3c2.6-2.1,2.8-2.6,2-4.7c-0.5-0.1-1-0.1-1.5-0.2
	c-1.2-0.3-1.5-0.8-1.3-2.4c0.2-1.3-0.1-3.4,1.7-3.4c2.1-0.1,3.4-0.5,4.1-2.6c0.1-0.2,0.5-0.3,0.7-0.5c0.7-0.7,1.4-5.3,0.9-6
	c-0.1-0.2-0.4-0.3-0.5-0.4c-1.1-0.5-0.9-1.4-0.3-2.1c1.1-1.4,0.8-2.9,0.3-4.3c-0.2-0.6-0.5-1.1-0.7-1.7c-0.9-2.4-0.6-5,0.9-7.1
	c0.8-1.3,1.7-2.5,2.4-3.8c2.4-4.4,5.6-7.7,10.9-8.6c1-0.2,1.2-1,0.8-1.9C136.6,490.9,135.4,490.5,134.4,490.8z"/>
<path className="cls-1" d="M82.4,715c0.1,0.2,0.8,0.4,0.9,0.3c0.3-0.3,0.4-0.7,0.4-1.1c-0.4-0.9-0.9-1.8-1.4-2.7c-0.8-1.3-2-2.4-2.3-3.8
	c-0.5-2.1-1.8-4-3.6-5.3c-0.9-0.6-1.5-1.6-1.8-2.6c-0.3-1.1-1-2.1-1.9-2.9c-0.4-0.4-1.4-0.4-2.1-0.6c-0.1-0.1-0.2-0.2-0.3-0.4
	c0.4-1.7,2.6-1,3.4-2.4c-1.8-1.7-2.2-4-2.8-6.3c-0.4-1.5-0.9-3-1.3-4.5c-0.2-0.9-0.6-1.5-1.7-1.4c-0.2,0-0.5-0.3-0.7-0.5
	c0.1-0.3,0.1-0.6,0.2-0.7c1.8-1.6,2.2-3.6,2-6c-0.1-0.8,0-1.5-0.1-2.3c-0.1-1.8,0.4-3.6,1.2-5.2c1-2,2.4-3.5,4.7-3.8
	c0.4-0.1,0.9-0.1,1.4-0.2c1.5-0.4,1.9-1.4,0.8-2.4c-1.1-1.1-1.7-2.7-1.4-4.2c1,0.5,1.8,1.4,2.2,2.5c0.5,1,1.1,1.7,2.4,1.5
	c1.3-0.2,2.9-0.2,3.5-1.9c1-2.6,2.6-5,4.5-7c0.9-0.9,1.3-2.2,1.1-3.5c-0.2-1.7-0.2-3.5-0.1-5.2c0.1-1.2,0.9-2.5-0.6-3.4
	c-0.1-0.1-0.1-0.3-0.1-0.4c0.4-2.4,0.7-4.9,2.6-6.6c1.2-1,2.1-2.2,2.6-3.6c0.9-2.1,1.8-4.3,4-5.5c0.3-0.2,0.5-0.5,0.7-0.8
	c1.7-2.4,4.1-4.2,6.9-5.1c1-0.3,1.8-1,2.3-2c0.9-2.4,2.5-3.6,5.2-3.3c1.5,0.2,1.7-0.1,1.9-2c-0.5-0.6-1.8-0.4-1.7-1.7
	c0.2-1.7,1.7-1.4,2.7-2c-0.1-0.7-0.2-1.5-0.4-2.2c-0.8-2.5-1.5-2.7-3.6-1.2c-0.7,0.4-1.4,0.7-2.1,0.9c-0.1-0.2-0.2-0.4-0.4-0.5
	c0.5-0.6,1.2-1.2,1.8-1.7c0.7-0.5,1.5-0.6,2.2-1.1c0.6-0.4,1.1-0.9,1.5-1.5c1-3.2,1-6.6,0.1-9.8c-0.3-1.2-1.3-2-2.5-2
	c-1.5-0.1-3,0-4.5,0.1c-0.8,0.1-1.6,0.1-2.4,0.1c-2.7-0.5-5.3-1.1-8-1.6c-0.1-0.2-0.2-0.4-0.2-0.6c-0.5-3-2.6-5.4-5.5-6.3
	c-3.8-1.3-5.5-4-6.1-7.6c-0.4-2.1-0.5-4.3-0.7-6.4c-5,0.2-22.6,1.1-37,1.9c-12.3,1.1-25.6,2.1-30.8,2.4c-0.3,0.4-0.7,0.8-1.2,1.1
	c-0.8,0.5-1.1,1.1-0.8,1.8c0.1,0.7,0.4,1.3,0.8,1.8c1.7,1.7,2,3.9,2,6.1c0.1,2.7,0,5.4-1.7,7.7c-1.2,1.7-1.1,4,0.1,5.7
	c1.2,2,1,3.3-0.6,5.1c-0.8,0.8-1.6,1.6-2.5,2.3c-1.7,1-2.5,3.1-2,5c0.6,2.1-0.1,4.3-1.8,5.7c-1,0.7-1.2,2-0.5,2.9c0,0,0,0,0,0.1
	c1.2,2.3,2.5,4.6,3.9,6.8c1.2,1.9,1,3.6-1,4.6c-0.7,0.4-1.3,1-1.5,1.8c-0.4,1.3-0.3,2.7,0.3,4c0.7,1.6,0.7,3.4,0.2,5.1
	c-0.8,3.2-2.6,6-5.3,7.9c-1.3,0.9-2.1,2.3-2.2,3.9c-0.3,2.5-0.9,5-1.7,7.4c-0.5,1.2-1.2,2.4-2.1,3.4v1.8c0.3,0.4,0.7,0.6,0.8,1
	c0.6,1.6,1.1,3.3,1.6,4.9c0.3,0.7,0.3,1.5,0.2,2.2c-0.6,2.4,0.4,4.5,1.5,6.4c1.3,1.9,1.9,4.3,1.7,6.6c-0.2,1,0.4,1.9,1.4,2.1
	c0,0,0.1,0,0.1,0c2.5,0.8,4.2-0.1,4.9-2.7c0.3-1.5,1.6-2.6,3.1-2.8c1.1-0.2,2.1-0.5,3.3-0.7c0.7,0.9,1.5,1.9,2.3,2.9
	c1.7,2.1,1.8,3.5,0.2,5.7c-0.4,0.5-0.7,1.1-1.2,1.7c1.8,1.7,1.5,3.7,0.8,5.6c0.6,0.8,1.1,1.4,1.5,2c-1.5,2.6-1.6,4.2-0.2,5.8
	c0.7,0.6,1.4,1.2,2.1,1.7c1.6,1,2.8,2.6,3.2,4.5c0.5,2.7,2,3,4.3,2.8c6-0.7,11.9-1.3,17.9-1.9c2.1-0.2,4.3-0.5,6.5-0.5
	c1.1,0,2.2,0.3,3.1,0.8c2.4,1.8,5.4,2.3,8.3,1.5c3.1-0.9,5.9-0.4,8,2.4c0.9,1.4,2.7,1.9,4.2,1.3C80.6,713.9,81.8,713.5,82.4,715z"/>
<path className="cls-1" d="M4.5,435.6l1.5,1.8c-0.4,0.4-0.6,0.7-0.8,0.7c-1.8,0.7-2.1,2.1-2.3,3.8c-0.3,1.9,0.2,3.8,1.2,5.4c0.8,0.7,1,1.9,0.5,2.9
	c-0.8,1.2-1.3,2.6-1.4,4c2.2,0.1,2.6,0.4,2.8,0.6c0.5,0.4,0.8,0.9,0.9,1.5c0,0.1,0,0.1,0.1,0.1h0.1c0.1-0.6,0.4-1.6,1.4-1.6
	c0.6,0.1,1.1,0.3,1.6,0.6c0.3,0.1,0.7,0.4,0.9,0.4h0.2c0.4,0.2,0.9,0.2,1.4,0.1c0.9-0.6,1.8-1.1,2.8-1.4c0.1,0,0.2-0.1,0.3-0.1
	c0.6-0.1,0.9-0.2,1-0.5c0.3-1.1,0.5-2.2,0.4-3.3c-0.1-0.1-0.1-0.2-0.1-0.4c-0.4-0.8-0.6-1.8-0.5-2.7c0.3-1,2.6-3,3.3-3.3
	c0.3-0.1,0.7-0.2,1.1-0.3c0.5-0.1,1-0.3,1.5-0.5c0.2-0.1,1-0.8,1.6-1.3c1-1,2.1-1.8,3.3-2.4c0.5-0.2,1.1-0.2,1.7-0.2
	c0.5,0,1.1-0.1,1.6-0.2c1-0.6,1.8-1.4,2.5-2.4c0-0.2-0.1-0.6-0.1-1c-0.2-2.1-0.4-4,0.1-5.1c0.4-0.8,1-1.5,1.6-2.1
	c0.3-0.3,0.6-0.6,0.8-0.9c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.9,0.5-1.7,0.9-2.5c0.8-0.9,1.7-1.7,2.6-2.4c2.1-1.9,2.7-2.1,2.8-2.1
	c0.2-0.1,0.5-0.2,0.7-0.2c0.4-0.1,0.8-0.3,1.2-0.5c0.1-0.3,0.2-0.7,0.2-1c0-0.5,0.2-0.9,0.4-1.3c0.9-1.4,1.8-2.1,2.6-2
	c0.4,0.1,0.8,0.3,1.1,0.6l0.2,0.2c1.1-0.4,2.2-1,3.1-1.8l0.1-0.1c0.8-0.8,1.1-1,1.4-1.7c0.1-0.1,0.1-0.3,0.2-0.5
	c0.2-0.7,0.6-1.3,1.1-1.8c0.4-0.3,1-0.5,1.5-0.5H56l0.1-0.1c0.2-0.7,0.4-1.4,0.5-2.1c0.1-0.9,0.4-1.8,0.9-2.6
	c1.7-1.5,4.6-4.2,5.9-4.6c0.9-0.1,1.8,0,2.6,0.4c0.3,0.1,0.6,0.2,0.9,0.3c0.8-0.2,0.9-0.4,0.9-0.4c-0.7,0.2-1.6-0.1-2-0.7
	c-0.3-0.7-0.4-1.4-0.2-2.1c0.1-0.3,0.1-0.5,0.1-0.8c-0.4-0.2-0.7-0.4-1-0.7c-1.5-0.9-3.1-1.8-3.1-3.2c0-0.5-0.7-10.9-1.4-22.8
	c-1.2-0.2-2.4-0.5-3.5-1c-0.8-0.5-1.5-1-2.1-1.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4,0.3-0.8,0.5-1.2,0.7
	c-0.8,0.2-1.4-0.4-2-0.9c-0.2-0.2-0.5-0.5-0.7-0.5c-0.6-0.1-1.2-0.2-1.8-0.2c-0.4,0-0.7,0-1.1-0.1c-0.7-0.1-2.8-0.9-3.1-2.4
	c-0.2-0.7-0.4-1.3-0.7-2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0.1-0.6,0.3-0.9,0.4c-0.7,0-1.4-0.2-2-0.6c-0.2-0.1-0.5-0.3-0.7-0.4
	c-0.6-0.1-1.2,0-1.8,0.1c-0.8,0.1-1.6,0.1-2.4,0.1c-1.5-0.1-3.9-1.8-4.5-2.5c-0.4-0.5-0.5-1.5-0.5-3.8v-0.2
	c-0.2-0.5-0.5-0.9-0.8-1.3c-0.4-0.4-0.6-0.8-0.8-1.3c-0.4-0.5-0.8-0.9-1.4-1.1c-0.2-0.1-0.3-0.2-0.5-0.3c-1.2-1-2.3-2.1-3.3-3.3
	c-0.3-0.6-0.5-1.3-0.4-2v-0.2c-0.1,0.1-0.4,0.1-0.5,0.2c-1,0.4-2.2,0.5-3.2,0.1c-1-0.4-0.9-1.7-0.7-2.9c0.1-0.3,0.1-0.6,0.1-0.9
	c-0.4-0.6-0.9-1.1-1.5-1.5c-0.1,0-0.2,0-0.2-0.1c-1.3-0.4-2.6,0.4-3,1.7c0,0.1-0.1,0.3-0.1,0.4c-0.4,2.1-1.9,3.9-3.9,4.6
	c-1.8,0.8-2.2,2.3-2.1,4c0,0.5,0.2,1,0.2,1.4c-0.2,0.2-0.2,0.4-0.4,0.5c-1.4,0.5-2.2,2.1-1.7,3.5c0,0,0,0,0,0
	c0.1,0.6,0.1,1.2-0.2,1.8c-0.5,1.3-0.4,2.7,0.2,3.9c0.8,2.3,1.5,4.6,0.2,6.9c0.7,0.8,1.2,1.6,1.8,2.3c-2.3,2.3-2.4,4-0.6,6.6
	c0.4,0.6,0.9,1.2,1.4,1.8c1.6,2,2.8,4.1,2.1,6.8c-0.3,1.5,0.3,3.1,1.5,4c0.8,0.8,1.6,1.6,2.2,2.6c1.7,2.6,1.4,4.2-1,6.3
	c-1,0.8-2,1.5-3,2.1c-0.5,0.4-1,0.6-1.5,1c-2.4,1.8-2.7,4.2-0.9,6.6c0.3,0.3,0.5,0.7,0.7,1c0.9,1.8,0.8,3.2-0.7,4.6
	c-0.8,0.6-1.2,1.6-1.2,2.6c0.1,0.8-0.4,1.6-1.1,2c-2.1,1.7-2.3,3.6-0.9,6c0.3,0.4,0.5,0.8,0.7,1.2c0.3,1.1,0.3,2.3,0.2,3.5
	c-0.1,0.5-1.2,1-1.9,1.4c-0.8,0.3-1.2,1.2-0.9,2c0.1,0.1,0.1,0.3,0.2,0.4C3.5,434.6,4,435.1,4.5,435.6z"/>
<path className="cls-1" d="M65.9,31.3c-0.2,0.8-0.3,1.5-0.5,2.2l-3,12.3c0.6,0.3,1.1,0.9,1.8,1.3c0.7,0.4,1.3,0.9,1.8,1.5c0.6,0.5,1,1,1.4,1
	c0.3,0.1,0.5,0.1,0.8,0.1c0.8,0,1.5,0.4,2,1c0.3,0.5,0.5,1.1,0.5,1.7c0,0.2,0.1,0.6,0.1,0.7c0.4,0.4,0.9,0.6,1.4,0.7h0.1
	c1.1-0.2,2.2,0.4,2.6,1.5c0.1,0.3,0.7,0.2,0.8,0.2c0.7-0.1,0.7-0.3,0.7-0.4v-0.2c0-1,0.5-1.8,1.4-2.2c0.5-0.2,1-0.1,1.4,0.1
	c0-0.6-0.1-1.3-0.2-1.9c-0.3-1.2-0.3-2.3,0-3.5c0.5-1.3,0.7-2.6,0.7-4c-0.3-0.3-0.7-0.6-1.1-0.8c-1-0.7-2.3-1.5-2.1-2.8
	c0-0.2,0.1-0.5,0.1-0.7c0.1-1.4,0.2-3.9,2.3-4.7c1.6-0.7,3.5-0.6,5,0.5c0.4,0.2,0.7,0.4,1.1,0.6c1.6,0.8,3.3,1.5,3.6,2.9
	c0.2,0.9,0.1,1.8-0.1,2.7c-0.1,0.9-0.2,1.8-0.1,2.7c0.3,0.3,0.6,0.5,0.9,0.7c0.8,0.4,1.4,1,1.8,1.8c0.1,0.4,0.1,0.9,0.1,1.3
	c0,0.2,0,0.5,0.1,0.6c0.3,0.4,0.7,0.7,1.1,0.9c0.1,0,0.2-0.1,0.4-0.1c0.6-0.2,1.3-0.1,1.8,0.2c0.9,0.6,1.6,1.5,1.8,2.6
	c0.1,0.6,0,1.2-0.1,1.8c-0.1,0.2-0.1,0.5-0.1,0.7c0.1,0.2,0.2,0.3,0.3,0.5c0.9,0.4,1.5,1.3,1.5,2.3c0,0.7,0.1,0.9,0.4,1.1
	c0.8,0.8,1.2,2,1,3.1c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0,0.6-0.1,0.9-0.2c0.4-0.1,0.7-0.2,1.1-0.2c1.1,0.1,2.2,0.5,3.1,1.3
	c0.5,0.4,1.2,0.8,1.5,1l0.2-0.2c0.4-0.6,1.1-1,1.9-1.1c1.3,0,2.4,1,2.4,2.3c0,0,0,0,0,0c0,0.5,0.2,0.5,0.5,0.5h0.2
	c1.2,0,1.7-0.1,2-0.8c0.8-1.6,1.4-1.8,2.4-1.8h0.1c1.2,0,2.3,1.3,2.7,2.6c0.1,0.6,0.6,1,1.2,1c0.1,0,0.3,0,0.4-0.1
	c0,0,0.1-0.1,0.1-0.5c0.1-0.4,0.1-0.7,0-1.1c0-1.1-0.1-2.2,0.6-2.9c1.1-1.2,3.1-0.8,3.7-0.6c0.8,0.2,0.9,0.2,1.4-0.2
	c0.2-0.3,0.4-0.5,0.6-0.8c0.8-1.1,1.8-2.6,3.3-3.2l-0.8-15.5c-1-0.5-2-0.7-3.1-0.8c-0.4,0.2-0.8,0.4-1,0.8c-0.8,1-2,1.6-3.3,1.8
	c-1.6,0-2.5-1.4-3.2-2.4s-1.1-1.6-1.6-1.6c-0.8,0-0.8,0.1-0.7,0.7c0.1,0.9-0.1,1.7-0.7,2.4c-0.5,0.5-1.2,0.8-1.9,0.7
	c-1.8,0-2.2-2.6-2.3-3.6c-0.1-1.2-0.2-2.3-0.2-3.5c0-0.1,0-0.2-0.5-0.5c-0.5-0.3-2.4-1.4-2.4-3c0-0.8,0.4-1.6,1.1-2l0.3-0.3
	c0.1-0.4,0.1-0.9,0-1.3c0-0.2-0.1-0.3-0.1-0.5c-0.1-1-1.4-1.1-1.6-1.1c-1.7,0.2-3.4-0.7-4.3-2.1c-1-1.6-1.9-9.3-1.5-10.4l0.5-1
	c0.7-1,1.1-2,1.3-3.2c0.1-1.6,0-3.3-0.1-4.9c-2.7,0.1-5.5,0.1-8.2,0.1c-1.1,0.2-2.2-0.3-3-1.1c-0.7-0.8-1.7-1.3-2.8-1.3
	c-1,0.1-1.9-0.6-2.1-1.6c-0.3-1.4-1.2-1.5-2.4-1.4c-1.6,0-3,1.3-3.1,2.9c-0.1,0.7-0.2,1.5-0.4,2.2c-0.4,2.2-0.5,2.3-2.8,2.3
	c-1.2,0-2,0.3-2.3,1.6c-0.2,0.9-0.8,1.1-1.7,1.1c-2.4,0.1-2.8,0.5-3.3,2.8c-0.2,1-0.8,2-1.7,2.5c-0.8,0.6-1.6,1.1-2.5,1.5
	c-1.2,0.5-2,1.6-2.1,2.9c-0.1,1.8-1.1,3.5-2.6,4.5c-1.7,1.2-1.7,2.7-0.1,4.2C64.1,29.8,65,30.4,65.9,31.3z"/>
<path className="cls-1" d="M171.8,54.6c1.1,0.3,2.2,0.6,3.3,0.8c0.9,0.2,1.9,0.5,2.8,0.8c1.4,0.5,2.6,1.4,3.7,2.4c0.2,0.1,0.4,0.2,0.5,0.4h0.4
	c0.5,0,1,0.1,1.5,0.4c0.3,0.2,0.5,0.7,0.8,1.1c0.1,0.2,0.2,0.3,0.3,0.5h0.2c0.9,0.1,1.5,0.1,1.9,0.5c0.3,0.4,0.5,0.9,0.5,1.4
	c0.1,0.3,0.2,0.7,0.2,1c0.3,0.1,0.5,0.1,0.8,0.1c1.2,0.1,2.3,0.5,3.3,1c1.3,0.8,2.5,1.8,3.5,3c0.1,0.2,0.3,0.5,0.4,0.7
	c0.6,1,1.2,2,2,2.9c0.4,0.3,0.8,0.6,1.2,1c0.2,0.2,0.5,0.5,0.7,0.5c1,0.3,2,0.7,2.9,1.2c0.3,0.3,0.6,0.6,0.9,1
	c0.1,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.3,0.1,0.5,0.2c0.8,0.2,1.5,0.6,2.1,1.1c0.1,0.1,0.9,0.8,1.7,1.5c3.2,2.8,4.9,4.3,5.4,5.1
	c0.5,0.9,0.9,1.8,1.2,2.7c0.2,0.6,0.4,1.1,0.7,1.6c0.6,0.9,1.4,1.8,2.2,2.6c0.3,0.1,0.5,0.1,0.8,0.1c0.7,0,1.3,0.2,1.9,0.4
	c0.5,0.4,1,0.9,1.3,1.5c0.2,0.3,0.4,0.6,0.7,0.8L223,94c0.4,0.3,0.7,0.7,1,1.1c0.2,0.4,0.4,0.8,0.6,1.2c0.4,0.7,1.1,2.4,1.4,2.7
	c0.1,0.1,0.2,0.1,0.2,0.2c0.5,0.5,1.1,1,1.8,1.4c0.1-0.1,0.2-0.2,0.4-0.2c0.5-0.2,1.2-0.7,2-0.2c0.6,0.3,1.1,0.9,1.1,1.6
	c0,0.2,0,0.3,0.1,0.5c0,0.3,0.1,1,0.1,1.3c0.1,0.1,0.4,0.3,0.5,0.5c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.2,0.2,0.2,0.4
	c0.2,0.3,0.4,0.5,0.6,0.7c0.2-0.1,0.8-0.2,1.2-0.4c0.7-0.2,1.5-0.4,2.3-0.5c1.2,0.2,2.2,0.8,3,1.7c0.7,0.6,1.5,1,2.3,1.4
	c0.3,0.1,0.5,0.2,0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0.5,0.2,0.9,0.6,1.2,1.1c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.2,0.1,0.5,0.2,0.7
	c0.1,0.2,0.2,0.1,0.4,0.1c0.5,0.1,0.9,0.4,1.2,0.8c0.2,0.3,0.3,0.5,0.4,0.8c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.3
	c0.4,0.2,0.7,0.5,0.9,0.9c0.7,1.3,1.1,2.8,1.2,4.3c0.2,0.7,0.6,1.3,1.1,1.8c0.9-1.9,2.3-3.5,3.9-4.9c0.6-0.6,1-1.3,1.1-2.1
	c0.6-2.2,1-4.4,1.2-6.7c0.4-3.3,1.8-5.6,5-6.7c1.3-0.3,2.1-1.7,1.8-3c0,0,0,0,0,0c-0.3-1.2,0.5-2.4,1.7-2.6c0.1,0,0.3,0,0.4-0.1
	c1.1-0.2,1.8-0.7,1.9-1.8c0.1-1.3,0.8-2.4,1.8-3.2c1.5-1.3,2.4-3.2,2.3-5.2c0-1.1-0.3-2-1.4-2.3c-0.9-0.1-1.6-0.9-1.7-1.8
	c-0.1-1-0.7-1.4-1.7-1.3c-0.4,0-0.8,0-1.2,0c-1.1-2.4-1.8-3-3.5-2.2c-2.2,1-3.9,0.2-5.3-1.2c-2.4-2.2-5.3-3.9-8.5-4.8
	c-1.1-0.4-2.2-0.8-3.3-1.4c-0.7-0.2-1.3-0.7-1.7-1.3c-0.6-1.1-1.4-1.2-2.6-1.4c-1.4-0.3-2.7-0.9-3.8-1.7c-1-0.8-1.8-1.8-2.4-2.9
	c-1-1.6-2.1-2.7-4.2-2.4c-0.4,0-0.8,0-1.1-0.2c-3.4-1.3-6.6-3.1-9.6-5.2c-1-1-2.5-1.5-3.9-1.2c-1.4,0.3-2.9-0.1-3.9-1.2
	c-1.6-1.6-3.9-2.5-6.2-2.2c-0.7,0.1-1.3,0-1.9-0.4c-1.1-1.3-2.6-1.7-4-2.3c-1.8-0.7-3.1-1.8-3.2-3.9c0-0.9-0.6-1.8-1.5-2.1
	c-1.5-0.5-2.8-1.5-3.6-2.9c-0.3-0.4-0.7-0.7-1.1-0.8c-3-1.1-5.1-3.5-7.5-5.5c-1-0.9-2-1.8-3.1-2.5c-2.9-1.7-4.5-4.5-5.8-7.5
	c-0.4-0.8-0.7-1.4-1.4-1.6c-1-0.3-1.7-1.3-1.7-2.4c-0.1-1.1-0.2-2.1-1.5-2.4c-0.1,0-0.1-0.1-0.2-0.1l0.5,38.8
	C170.5,54.2,171.1,54.5,171.8,54.6z"/>
<path className="cls-1" d="M21,160.3c0.5-0.3,1.1-0.4,1.7-0.3c1.7,0.1,3.1,1.3,3.3,3c0.1,0.2,0.2,0.5,0.3,0.7c0.4,0.3,0.8,0.5,1.3,0.7
	c0.5,0.2,0.9,0.4,1.3,0.7c0.5,0.4,0.8,0.8,1,1.4c0.2,0.4,0.4,0.7,0.7,1c0.2,0.2,0.4,0.4,0.6,0.5c0.6,0.4,1.1,1,1.5,1.6
	c0.7,1,0.9,3,1,6.4c0,1.2-0.6,2.4-1.5,3.2c-0.4,0.4-0.5,0.7-0.4,0.9c0,0.2,0.1,0.4,0.2,0.6c0.2,0.5,0.4,1,0.4,1.6
	c0,0.4-0.1,0.7-0.2,1.1c-0.1,0.5-0.2,0.9-0.2,1.4c0.2,1.4,0.8,2.7,1.7,3.8c0.1-0.6,0.5-1.2,1.1-1.4c1.1-0.4,5.6,0.2,6.2,0.4
	c0.6,0.2,1.3,1,2,1.8c0.1,0.1,0.1,0.2,0.2,0.3h1.3c0.5-0.1,0.9-0.3,1.4-0.5c0.7-0.3,1.4-0.5,2.1-0.6c0.8,0.1,1.7,0.7,5.2,3.9
	c0.7,0.6,1.3,1.2,1.7,1.5c0.7,0.6,3.3,2.3,5.6,3.7c1.9,1.1,3.7,2.4,5.5,3.7c0.5,0.6,1,1.2,1.4,1.8c1.1,1.8,2.3,3.4,3.7,4.9
	c2.6,3,4.7,6.4,6.2,10.1c1.1,2.6,1.5,8.1,1.5,8.2c0.2,1.5,0.7,3,1.4,4.4c0.2,0.4,1.2,0.6,1.8,0.7c0.3,0.1,0.6,0.1,0.8,0.2
	c0.7,0.3,1.3,0.9,1.5,1.7c0.1,0.4,0.3,0.8,0.5,1.1c0.2,0.4,0.4,0.8,0.7,1.2c0.5,0.7,1.1,1.4,1.9,1.8c0.9,0.5,3.6,0.5,4.6,0.4
	c0.2,0,1.1-0.4,1.6-0.5c0.7-0.4,1.5-0.6,2.3-0.7c0.6-0.1,1.1-0.1,1.7,0c0.4,0.1,0.9,0.1,1.3,0c0.3,0,0.6-0.1,0.8-0.3
	c0.4-0.2,0.8-0.4,1.2-0.5c-0.1-2.3-0.3-5.7-0.5-9.1c-0.6-12.1-0.5-12.3-0.3-12.7c0.1-0.1,0.2-0.3,0.4-0.5c0.6-0.9,1.2-1.9,1.6-2.9
	c0.1-0.5,0.1-1,0.1-1.5c-0.1-0.8,0-1.6,0.4-2.3c0.8-1.1,1.4-2.3,1.8-3.6c0.1-0.5,0.2-1.5,0.3-2.6c0.2-2.7,0.4-3.9,0.8-4.5
	c0.6-0.7,1.3-1.3,2.1-1.8l-4.9-18.1l-1.1,0.4c-0.5,0.1-0.9-0.1-1.2-0.5c-0.6-1.4-1.4-2.8-2.4-4c-2.2-1.9-4.5-3.6-6.9-5.1
	c-0.5,0-0.9,0-1.4,0.1c-0.7,0.1-1.5,0.1-2.2-0.1c0,0-2.5-1.3-2.6-2.8c0-1.1,0-2.1-0.2-3.2c-0.3-0.2-0.6-0.4-0.9-0.7
	c-1.2-0.9-2.2-1.5-2.1-2.5c0.1-0.7,0-1.4-0.2-2.1c-0.2-0.4-0.5-0.8-0.9-1.1h-0.9c-5.7,0.1-8.1,0.1-8.5,0.1c-0.4,0-0.7,0.1-0.9,0.1
	v0.4c0,0.5-0.1,1.1-0.4,1.5c-0.1,0.1-0.1,0.1-0.1,0.2c-0.4,0.7-0.9,1.4-2.9,1.4c-1.5-0.2-2.9-0.8-4-1.8c-0.2-0.2-0.4-0.4-0.7-0.5
	c-0.4-0.1-0.7-0.1-1.1,0c-0.4,0.8-1.2,2.6-2.4,2.9c-2.3,0.6-3-0.2-3.3-1c-0.1-0.7-0.2-1.3-0.1-2c0-0.2,0.1-0.6,0.1-0.7l-0.4-0.4
	c-0.5-0.4-1-0.8-1-1.5v-0.2c0-0.3,0-0.5-0.1-0.8c-0.2-0.1-0.5-0.2-0.7-0.2l-0.4,0.4c-0.6,0.8-1.5,1.2-2.5,1.2
	c-0.8-0.2-1.6-0.7-1.9-1.5c0-0.1,0-0.1-0.1-0.1h0c-0.1,0-0.1,0-0.2,0.1c-0.8,0.3-1.6,0.1-2.2-0.5c-0.9-1.1-0.7-2.9-0.5-3.9
	c0-0.1,0-0.2,0.1-0.2c0.2-0.7,0-1.4-0.4-2l-0.1-0.2c-2.6-3.7-3.5-3.9-3.7-3.9c-0.7,0-1.3,0.6-2,1.3c-0.8,1-2,1.6-3.3,1.8
	c-0.5,0.1-1,0.1-1.5,0.1c-0.9,0.2-1.8,0.1-2.6-0.1c-0.5-0.2-1-0.5-1.5-0.9c-0.4,2.1-1.7,4-3.5,5.2c-1.2,0.9-2.1,2.2-2.3,3.7
	c-0.7,3.1-2,5.8-5.2,7.2c-0.2,0.1-0.5,0.3-0.7,0.4c0.5,0.2,1.1,0.3,1.6,0.3C20.1,160.6,20.5,160.5,21,160.3z"/>
<path className="cls-1" d="M33.7,142.9c0.5-0.1,1-0.1,1.5-0.1c0.8-0.2,1.5-0.7,2-1.3c0.9-0.8,1.9-1.8,3.4-1.8s3,1.4,5.3,4.7l0.1,0.2
	c0.7,1,0.9,2.3,0.7,3.5c0,0.1,0,0.2-0.1,0.3c-0.2,0.7-0.2,1.5-0.1,2.2c0.1,0,0.1,0,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.1
	c0.5,0.2,1,0.6,1.2,1.1c0.2,0.4,0.2,0.4,0.5,0.5c0.1,0,0.4-0.4,0.6-0.5c0.3-0.3,0.6-0.6,0.9-0.8c1.1-0.7,2.9,0.1,3.4,0.9
	c0.3,0.6,0.4,1.2,0.4,1.8c0.1,0.1,0.2,0.2,0.3,0.2c0.5,0.4,0.8,0.9,1.1,1.4c0.1,0.5,0.1,0.9,0,1.4c0,0.3-0.1,0.7-0.1,1
	c0.3,0,0.5,0,0.8-0.1c0.5-0.6,0.9-1.3,1.2-2c0.4-1.1,2-1,3-1h0.1c0.6,0.2,1.1,0.5,1.6,0.8c0.8,0.5,2,1.4,2.8,1.4
	c0.8,0,1-0.1,1.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.4,0.1-0.6c0.1-0.5,0.1-1.3,0.6-1.7c0.7-0.5,1.6-0.8,2.5-0.7
	c0.5,0,6.4-0.1,8.4-0.1h1.1c1.3,0,2.4,1.5,2.7,2.1c0.3,0.9,0.5,1.9,0.4,2.9c0.2,0.2,0.9,0.7,1.2,1c0.5,0.3,1,0.7,1.4,1.2
	c0.2,0.3,0.5,0.8,0.6,4.2c0.4,0.4,0.8,0.7,1.2,1c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.3-0.1,1.9,0c1.2,0.2,7.4,5.1,8.1,5.7
	c0.9,1.1,1.7,2.4,2.3,3.7l1.3-0.4c0.3-0.1,0.6-0.1,0.8,0.1c0.2,0.2,0.4,0.4,0.5,0.6l5,18.3c0.1-0.1,0.2-0.1,0.2-0.2
	c0.7-0.5,1.3-1,1.7-1.3l0.1-0.1c1.1-0.9,3.3-2.6,3.6-3.6c0.1-0.9,0.2-1.8,0.2-2.7c0.1-2.6,0.2-4.5,0.8-5.5c0.4-0.6,0.9-1.1,1.5-1.5
	c-0.7-1.7-1.4-3.5-1.8-4.2c-0.8-1.3-1.2-3.9-1.7-6.6c-0.1-0.6-0.2-1.2-0.4-1.8c-0.5-2.4-0.5-7.7-0.5-10.3v-1c0-1.5-0.7-5.5-1-7.6
	c-0.1-0.8-0.2-1.9-0.4-3c-0.4-0.2-0.8-0.5-1.4-0.8c-0.6-0.3-1.1-0.6-1.6-1h-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0.2-0.3,0.5-0.4,0.7
	c-0.9,1.6-1.5,2.4-2.1,2.7c-0.6,0.3-1.3,0.5-2,0.5c-0.3,0-0.6-0.1-0.9-0.2c-1.1-0.6-1.8-1.7-2-2.9c0-0.4,0.1-0.8,0.1-1.3
	c0.1-0.4,0.1-0.9,0.1-1.3c-0.1-0.3-0.5-0.5-0.8-0.4c0,0.1-0.1,0.1-0.1,0.2v0.1c0,0.8-0.7,1.3-1.5,1.3c0,0,0,0,0,0
	c-1.4,0-3.3-2.1-3.6-2.4c-0.3-0.3-0.5-0.6-0.8-1c-0.3-0.4-0.7-0.8-1.1-1.2l-0.1-0.1c-0.6-0.4-0.9-1-0.8-1.7c0.1-0.4,0.2-0.8,0.4-1.2
	c0.1-0.3,0.2-0.5,0.4-0.8c-0.2-0.3-0.4-0.6-0.7-0.9c-0.1,0-0.2,0.1-0.4,0.1c-0.6,0.2-1.3,0.1-1.8-0.3l-0.1-0.1
	c-0.9-0.6-1.3-1.6-1.1-2.6c0.9-1.9,2-3.8,3.4-5.4c0.5-0.6,1-1.1,1.5-1.6c1.5-1.5,2.7-2.9,2.7-4.8c-0.1-1.6-1.1-2.9-2.6-3.5
	c-0.1,0-0.1,0-0.2-0.1c-1.5,0-2.7-1.1-2.9-2.6c-0.4-1.1,0-2.3,1-3c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.2,0.2-0.5,0.2-0.8
	c0-0.1,0-0.1-0.1-0.1c-0.1-0.5-0.2-1-0.1-1.5c0.1-0.4,0.2-0.7,0.4-1c-0.5-0.4-1-0.8-1.3-1l-0.1-0.1c-0.3,0-0.5,0-0.8,0.1
	c-0.3,0.3-0.5,0.7-0.7,1l-0.1,0.2c-1.1,1.3-2.6,2-4.3,2.1c-1.9,0-3.7-1.7-4.3-2.4c-0.5-0.9-0.9-1.9-1.1-2.9
	c-0.1-0.2-0.1-0.5-0.2-0.6c-0.2-0.7-0.6-1.3-1.1-1.8c-1-0.9-1.7-2-2.1-3.3c-0.4-1.2-0.4-2.5,0.2-3.6c0.3-0.5,0.7-0.9,1.3-1.1
	c0.2-0.1,0.5-0.1,0.7-0.1c1,0,1.9-0.4,2.6-1c0.8-0.9,1-2.1,0.6-3.2c-0.5-0.1-1-0.3-1.5-0.6c-0.8-0.7-1.2-1.8-1-2.9
	c-5.8,0.3-27.8,1.3-32,1c-2.8-0.2-5.3-1.6-7-3.8c-0.3,0.2-0.5,0.3-0.8,0.4c-0.7,0.4-1.4,0.8-1.1,1.7c0.5,1.7-0.3,3-1.4,4.5
	c2,1.7,0.9,3.6,0.5,5.7c1.1,0.1,1.8,0.2,2.8,0.3c0.5,4.3,0.9,8.5,3.1,12.3c-2.9,1.4-3.3,1.8-3.7,3.5c-0.4,1.7-0.2,3.5,0.7,5.1
	c1,1.6,2.1,3.1,3.3,4.5c0.4,0.5,0.9,0.8,1.3,1.3c0.9,0.9,0.9,2.3,0.1,3.2c-0.3,0.3-0.6,0.5-1,0.6c-1.2,0.5-2.4,0.8-3.6,1.1
	c-2,0.4-3.6,1.9-4.2,3.8c-0.5,1.8-0.9,3.6-1.2,5.5c-0.1,1.2-0.7,2.3-1.7,3c-1.4,1-2.2,2.5-2.3,4.2c0.8,0.6,1.6,1.1,1.9,1.3
	C32.3,143,32.6,143,33.7,142.9z"/>
<path onClick={() => { handleChangeSlide(4);  handleChangeSlide2(4);  setProvincia('corrientes'); setDisplay(!display) }} className="active corrientes"  d="M307.5,153.1c0.6-0.2,1.3-0.6,1.8-1c-0.5-0.6-1.1-1.4-2-2.4c0.1,0.2-0.3-0.2-0.7-0.6c-0.6-0.6-1.2-1.2-1.7-1.9
	c-0.4-0.7,0-1.5,0.2-2.1c0.1-0.2,0.2-0.3,0.2-0.4c0.2-0.5,0.3-1.1,0.1-1.7c-0.6-1-1.3-1.9-2.2-2.7c-0.5-0.5-0.4-1.4-0.4-2v-0.2
	c0-0.1-0.2-0.3-0.4-0.5c-0.2-0.2-0.2-0.2-0.3-0.2c-0.4-0.5-0.6-1.1-0.6-1.7l-0.1-3.2c-2.3-1.6-4.4-1.5-7.2,0.3
	c-0.9,0.8-2.3,0.9-3.3,0.2c-2.2-1.4-5.1-1.3-7.3,0.1l-3,1.5c-1.4,0.8-3.2,0.8-4.5-0.2c-1.1-0.7-2.2-1.4-3.3-2
	c-1.2-0.8-2.4-1.5-4-1.1c-0.8,0.3-1.7-0.2-2-1c0,0,0-0.1,0-0.1c-0.5-1.4-1.5-1.4-2.7-1.4c-0.9,0-2,0.1-2.6-0.4
	c-2.2-1.5-4.4-1.1-6.6-0.4c-1.4,0.4-2.8,0.7-4.2,0.8c-0.7,0.1-1.4,0-2-0.4c-0.2,0.6-0.6,1.1-1.1,1.4c-0.4,0.2-0.9,0.4-1.4,0.5
	c-0.3,0.1-0.5,0.2-0.8,0.2c-0.6,0.3-1.2,0.9-1.5,1.5c0,0.9,0,1.7,0.2,2.6c0.5,1,0.6,2.1,0.5,3.2c-0.2,1.4-0.6,3.2-1.8,4
	c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4,0.3-1,0.7-1.2,0.9v0.7c0.1,1.1-0.1,2.3-0.4,3.4c-0.3,0.9-0.8,1.8-1.5,2.5l-0.4,0.4
	c-0.5,0.4-1,0.8-1.4,1.3c0,0.2,0.1,0.7,0.1,1c0.8,8.1,0.7,8.4,0.4,8.8s-0.7,0.7-1.7,1.5c-0.4,0.4-0.9,0.8-1.4,1.1
	c-0.1,0.2-0.3,0.9-0.5,1.5c-1.5,5.1-1.9,6.1-2.4,6.5c-0.5,0.4-0.9,0.7-2,1.4c-0.6,0.4-1.2,0.8-1.8,1.1c-0.6,1.4-1.1,2.8-1.5,4.2
	c0.2,0.5,0.4,1,0.5,1.5c0.4,0.9,0.7,1.8,0.8,2.7c-0.3,1.3-0.7,2.6-1.2,3.9c-0.3,0.9-0.6,1.8-0.9,2.7l0.4,0.8
	c0.4,0.6,0.7,1.3,0.8,2.1c-0.2,0.7-0.4,1.5-0.7,2.2c-0.3,0.8-0.8,2.2-0.9,2.6v1c3.6-0.5,4.2-0.3,4.4-0.2c0.6,0.4,1,1,1.2,1.7
	c0,0.1,0,0.1,0.1,0.2c0.4,0.1,0.9,0.2,1.3,0.1c0.2-0.3,0.4-0.6,0.5-0.9c0.8-1.4,1.4-2.3,2.2-2.6c0.1-0.1,0.3-0.1,0.4-0.2
	c0.8-0.3,1.8-0.8,2.8-0.2c0.2,0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.3-0.1,0.5-0.2,0.8-0.3c0.8-0.4,1.6-0.7,2.5-0.9
	c1.1-0.2,4.1,0.1,5.3,1c0.5,0.4,0.8,0.9,1.1,1.4c0.1,0.2,0.3,0.5,0.5,0.7c0.4,0.4,1,0.6,1.6,0.6c0.3-0.1,0.5-0.2,0.8-0.4
	c0.5-0.4,1.1-0.6,1.7-0.6c1.1,0.2,2.1,0.7,2.8,1.5c0.5,1.1,0.5,2.4,0,3.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.6,1-0.7,1.2
	c0,0.4,0,1.4,0.5,1.8c1,0.6,2.1,1.1,3.2,1.3c0.5-0.7,0.7-1.5,0.7-2.3c-0.1-1.9,1-3.6,2.7-4.3c1.5-0.7,2.7-2.1,3.1-3.7
	c0.4-1.2,1-2,2.4-1.8c0.4,0.1,1-0.3,1.4-0.6c1.2-0.9,2-2.4,2-3.9c0-2.1,0.7-2.9,2.7-3.1c1-0.1,2-0.6,2.7-1.4c2.3-2.3,4.6-4.8,7-7.1
	c1.1-1.1,2.3-2,3.7-2.8c2.1-1.1,3.7-2.5,4-5.1c0.1-0.7,0.2-1.4,0.5-2c0.4-0.6,0.9-1.3,1.5-1.4c1.3-0.4,2.4-1.4,2.8-2.7
	c0.9-1.9,2-3.7,4.1-4.6c0.4-0.2,0.7-0.6,0.8-1c0.9-2.1,1.2-2.3,3.6-1.8c1,0.2,1.9,0.4,2.7-0.8c-0.3-0.5-0.7-1-1.1-1.4
	c-0.7-0.7-0.7-1.8,0-2.5C307.1,153.3,307.3,153.2,307.5,153.1z"/>
<path className="cls-1" d="M115,165.6c0.1,0.6,0.2,1.3,0.4,1.9c0.4,2.2,0.8,4.9,1.4,5.9c0.5,0.8,1.2,2.7,1.9,4.3c0.9-0.3,1.8-0.5,2.8-0.6
	c0.5,0,1.5-0.1,2.6-0.2c6.1-0.5,7.8-0.5,8.4-0.1c0.8,0.7,1.5,1.6,2,2.6c0.4,0.7,0.8,1.3,1.2,1.9c1.5,1.3,2.8,2,3.9,1.9
	c1.8,0,9-0.2,9.1-0.2c0.3,0,0.6,0.1,0.8,0.4l2,2.3l19.4-0.4c0.4,0,0.7,0.2,0.9,0.5l3.6,5.9l6.5-47.6l0.4-53.9l-35.7,0.5h-2.3l-9,0.1
	c-0.2,0-0.3-0.1-0.5-0.1l-4-2c-1.4,3.2-5.2,12.1-6.4,14.2c0.9,9.1,0.8,9.3,0.7,9.5c-0.2,0.4-0.5,0.6-1.2,1.1c-0.7,0.5-1.4,1-2,1.6
	c-0.1,0.3-0.2,1-0.4,1.7c-0.3,1.6-0.7,3.2-1.4,4.7c-0.3,0.5-0.7,1-1.2,1.3c0,0.1,0,0.3-0.1,0.4c0.6,0.4,1,1,1,1.7
	c-0.1,0.6-0.6,1.2-1.2,1.4c-0.1,0-0.1,0-0.1,0.1v0.2c0.2,0.8,0.1,1.7-0.3,2.4c-0.3,0.4-0.7,0.7-1.1,0.9c0.2,0.5,0.2,1.1,0.2,1.7
	c-0.1,0.4-0.3,0.9-0.5,1.3c-0.1,0.3-0.2,0.5-0.3,0.8c0,0.2,0.1,0.4,0.2,0.5c0.4,0.8,0.6,1.8,0.7,2.7c-0.1,1.7-1,3.2-2.4,4.1
	c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0-0.2,0.1-0.3,0.1s-0.1,0.2-0.1,0.3c-0.2,0.8-0.4,1.6-0.8,2.3c0.1,1.1,0.2,2.1,0.3,2.9
	c0.1,0.6,1,5.8,1.1,7.9v1C114.5,159,114.7,162.3,115,165.6z"/>
<path className="cls-1" d="M353.7,117.7c1.4-2.2,1.4-4.5,1.3-7c0-1.6-0.5-3.1-1.5-4.3c-0.6-0.8-0.9-1.7-0.7-2.7c0.2-1.6,0.3-3.1,0.3-4.7
	c0-2.7-0.1-2.8-2.7-3.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.8-3.7-3.6-4.6-6.4-2c-1.3,1.2-2.9,1.9-4.6,2.2
	c-0.7,0.2-1.2,0.3-1.5,0.4c-0.6,2.1-1.1,3.8-1.6,5.5c-0.2,0.6-0.1,1.2,0.1,1.7c1.7,2.6,1,5.1,0.1,7.6c-0.5,1.4-1.2,2.6-2.8,3
	c-0.9,0.1-1.5,0.8-1.6,1.7c-0.1,1.5-1.2,2.4-2.1,3.4c-0.6,0.9-1.8,1.2-2.8,0.8c-1.4-0.5-2.9,0.2-3.6,1.5c-0.4,0.5-0.5,1.1-0.9,1.5
	c-0.7,0.9-1.4,2-2.6,1.9c-1.5-0.1-2.3,0.8-3.1,1.9c-0.7,0.9-1.3,2-2.2,2.4c-1.1,0.5-1.9,1.6-2.1,2.9c-0.6,2.6-2.5,3.4-4.8,3.3
	c-1.3-0.1-2.6-0.4-3.7-1v2.1c0,0.4,0.1,0.7,0.3,1l0.2,0.2c0.4,0.2,0.7,0.6,0.7,1.1v0.3c0,0.4-0.1,1.1,0.2,1.3c0.9,0.9,1.7,1.9,2.3,3
	c0.3,0.7,0.3,1.6-0.1,2.3c-0.1,0.2-0.1,0.4-0.2,0.5c-0.2,0.4-0.5,0.9-0.3,1.2c0.5,0.6,1,1.2,1.6,1.7c0.4,0.3,0.7,0.7,1,1.1
	c0.8,1,1.4,1.7,1.9,2.3c1.1-0.9,2.1-1.8,3.2-2.7c0.5-0.6,1.2-0.9,1.9-1.1c2.4-0.5,4.1-1.6,4.5-4.2c0.3-0.1,0.5-0.1,0.8-0.1
	c2.3,0.5,4.7-0.6,6-2.6c0.5-0.7,1.1-1.2,1.8-1.6c1.2-0.8,2.4-1.6,3.7-2.2c0.8-0.4,1.7-0.5,2.6-0.5c1.2-0.1,2.6,0.2,3.8,0.1
	c1.7-0.1,3.1-1.4,3.3-3.1c0.1-0.4,0.2-0.8,0.4-1.2c0.9,0.1,1.9,0.1,2.8-0.1c2.1-1,4.2-2.1,6.1-3.4c1.9-1.4,2.6-4,1.5-6.1
	C352.7,121,352.7,119.2,353.7,117.7z"/>
<path className="cls-1" d="M97.9,393.4c0.1,0,0.2,0.1,0.4,0.1c0.5-0.9,1.4-2.2,2.6-1.7c0.4,0.2,0.7,0.5,1,0.9c0.2,0.2,0.6,0.8,0.8,0.8
	c0.5,0,1.1-0.1,1.6-0.2c0.8-0.1,1.6-0.2,2.4-0.1c0.5,0.2,1,0.4,1.5,0.7c0.4,0.3,0.9,0.5,1.4,0.7c0.2-0.2,0.7-0.5,0.9-0.7
	c0.7-0.6,1.1-1,1.7-1c0.4,0,0.8,0.2,1.1,0.4c0.3,0.2,0.7,0.3,1,0.4c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.8-0.1,0.9-0.1l0.1-0.1
	c1.1-0.9,2.5-1.3,3.9-1.3c0.8,0.1,1.6,0.1,2.4,0.1c1.3-0.1,2.6,0,3.9,0.2c0.8,0.2,1.5,0.6,2,1.2c0.3,0.2,0.8,0.7,1,0.7
	c0.4,0,0.9-0.1,1.3-0.2c0.9-0.2,1.9-0.3,2.8-0.1c0.8,0.2,1.5,1,2.6,2.1c0.2,0.3,0.4,0.5,0.7,0.7c0.2,0,0.5,0,0.7,0.1
	c0.7,0,1.4,0.1,2.1,0.2c0.5,0.2,2.2,0.9,2.6,2c0.1,0.1,0.1,0.3,0.2,0.5c0.2,0.4,0.4,1.2,0.7,1.4c0.1,0.1,0.5,0.2,0.7,0.4
	c0.5,0.2,1,0.4,1.4,0.8c0.3,0.2,0.5,0.7,0.8,1.1c0.4,0.6,1,1.6,1.5,1.7c1.4,0.1,2.9,0.1,4.3,0l-1.2-96l-32.1,0.4l0.7,22
	c0,0.5-0.4,1-0.9,1.1L59.9,335c0.2,4.2,1.2,19.7,2.1,33.6c0.4,0.1,0.8,0.1,1.2,0.1c0.9,0,1.7,0,2.6-0.1c0.6-0.1,1.3-0.1,1.9,0
	c1.8,0.1,4.6,1.3,4.6,4.3c0.1,2.1-1.2,4-3.2,4.6c0,0.3,0.2,0.6,0.4,0.8c0.2,0.1,0.5,0.2,0.7,0.3c1,0.4,1.9,0.9,2.7,1.5
	c0.2,0.3,0.4,0.6,0.6,0.9c0.4,0.7,0.8,1.3,1.4,1.8H75c0.8,0.1,1.7,0.1,2.5,0c0.3-0.1,0.6-0.2,0.8-0.4c0.6-0.4,1.2-0.7,1.9-0.7
	c1.4,0,3,1.6,3.4,2.5c0.3,0.6,0.7,1.2,1.1,1.7c0.2,0,0.5-0.1,0.7-0.1c0.9-0.2,1.8-0.1,2.7,0.2l0.7,0.7c0.3,0.4,0.7,0.8,1.1,1.1
	c0.3,0.1,0.7,0.2,1,0.2c1.9,0.4,3,0.7,3.3,1.5c0.1,0.5,0.2,0.9,0.3,1.4c0.1,0.3,0.1,0.8,0.2,1.1h0.4c0.6-0.1,1.2,0,1.8,0.3
	c0.4,0.2,0.6,0.7,0.8,1v0.1C97.8,393.3,97.8,393.4,97.9,393.4z"/>
<path className="cls-1" d="M255.5,202.2c0.1-0.5,0.1-1-0.1-1.4c-0.3-0.2-0.7-0.4-1.1-0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4,0.2-0.8,0.5-1.3,0.7
	c-1.2,0.2-2.5-0.1-3.4-1c-0.4-0.3-0.7-0.7-0.9-1.2c-0.2-0.4-0.4-0.7-0.7-1c-1.1-0.5-2.4-0.7-3.6-0.6c-0.7,0.2-1.4,0.5-2.1,0.8
	c-0.3,0.2-0.7,0.3-1.1,0.4c-0.8,0.1-1.7-0.1-2.3-0.7c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.1-0.8,0.2-1.1,0.4c-0.3,0.2-0.3,0.1-0.4,0.2
	c-0.4,0.5-0.8,1.1-1.2,1.7c-0.4,0.7-0.7,1.1-0.9,1.4c-0.9,0.9-2.6,0.6-3.5,0.3c-0.9-0.3-1.1-1.1-1.2-1.6c-0.1-0.1-0.1-0.4-0.2-0.5
	c-1.2,0-2.4,0.2-3.6,0.4c-0.1,2.9-0.4,7-0.5,7.9c-0.3,1.4-2.6,5.2-3.9,6.7c-1.3,1.6-2.6,3.3-3.7,5.1c-0.1,0.1-4.6,8.5-6.1,10.1
	c-1.5,1.6-2.5,2.1-4.2,2.1c-0.9-0.1-1.8-0.4-2.5-0.9c-0.3-0.2-0.6-0.3-0.9-0.4c-0.7,0.1-0.8,0.4-0.8,0.6c-0.1,0.3-0.2,0.7-0.3,1
	c-0.3,1.1-0.6,2.2-0.7,3.3c0.1,0.4,0.3,0.8,0.5,1.2c0.4,0.8,0.6,1.6,0.8,2.5c0.2,1.8,0.4,4.1,0,4.9c-0.7,1.7-1.3,3.4-1.7,5.2
	c0,0.2,0.1,0.5,0.1,0.8c0,1.8,0.3,3.7,0.9,5.4c0.2,0.4,0.4,1,0.7,1.5c0.8,2,1.7,3.9,2.8,5.7c0.2,0.3,0.5,0.6,0.8,1
	c0.6,0.8,1.3,1.6,2,2.3c0.4,0.3,0.8,0.6,1.1,1c0.2,0.2,0.6,0.5,0.9,0.8c0.7,0.7,1.6,1.2,2.5,1.5c0.6,0.1,1.2,0.2,1.8,0.5
	c1.2,0.7,2.1,1.7,2.7,3c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0.4,0.7,0.7,1.1,1.2,1.3c1,0.1,2,0.1,3,0.1
	c1-0.1,2-0.1,3,0.1c1.2,0.2,2.4,0.6,3.4,1.4c0.3,0.2,0.5,0.5,0.7,0.8c0.4,0.5,0.8,1,1.2,1.4h0.4c1-0.2,2.1-0.1,3,0.4
	c0.7,0.5,1.2,1.2,1.6,2c0.1,0.3,0.3,0.6,0.5,0.8c0.4,0.2,0.9,0.3,1.4,0.3c1.3,0.1,2.5,0.4,3.7,1c1.1,0.6,2,1.6,2.5,2.7
	c0.1,0.2,0.2,0.5,0.4,0.7c0.8,0.3,1.7,0.5,2.6,0.6c-0.1-0.5-0.2-1-0.3-1.5c-0.4-1.5-1-3.1-1.7-4.5c-1.4-2.6-1.1-5.8,0.8-8
	c0.8-0.9,1.1-2.2,0.9-3.4c-0.1-1.3-0.2-2.6-0.2-3.8c0-1.7,0.6-2.1,2.2-2c0.8,0.1,1.6,0.2,2.4,0.2c2.2,0.1,2.8-1.6,2.3-3.2
	c-0.4-1.4-1-2.7-1.8-3.9c-1-1.3-1.3-3-0.7-4.6c0.3-0.9,0.8-1.8,1.1-2.7s0.7-2,0.4-2.8c-0.9-2.9-0.2-5.7,0.1-8.5
	c0.2-1.3,0.9-2.4,1.9-3.2c2.6-1.8,3.5-5.2,2.1-8.1c1.1-1.3,1.4-3.2,0.8-4.8c-0.1-0.6,0-1.1,0.4-1.6c1.1-1.7,1.7-3.3,0-5.3
	c0.5-0.2,1-0.4,1.6-0.5c0.9-0.1,1.8-0.2,2-1.1c0-0.8-0.3-1.6-0.8-2.2c-0.3-0.5-0.7-1-1.1-1.4c-1.5-0.4-3-1-4.3-1.8
	c-0.6-1-0.9-2.2-0.8-3.4c0.1-0.8,0.4-1.6,0.9-2.3C255.4,202.4,255.4,202.3,255.5,202.2z"/>
<path className="cls-1" d="M72.7,246.2c-0.2,0.7-0.4,1.4-0.7,2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,2.9,2.8,2.9,4.1c0,0.3,0,0.5-0.1,0.8
	c-0.1,0.4-0.1,0.9,0,1.3c0.1,0.1,0.1,0.1,0.1,0.2c0.4,0.6,0.7,1.3,0.7,2v2.5c0,0.2,0.1,0.5,0.1,0.7c0.3,1.6,0.5,3.3,0.6,4.9
	c0,0.6-0.1,1.2-0.2,1.8c-0.1,1-0.2,1.9-0.2,2.9c0.1,0.4,0.3,0.8,0.5,1.1c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.9,0.3,1.3
	c0.1,0.4,0.2,0.9,0.3,1.3c0.3,0.6,0.7,1.1,1.1,1.5c0.1,0.1,0.2,0.1,0.3,0.2c0.6,0.3,1.1,0.9,1.4,1.5c0.1,0.2,0.1,0.5,0.1,0.7
	c0,0.2,0.1,0.5,0.1,0.7c0.1,0.1,0.2,0.2,0.4,0.2c0.5,0.2,0.9,0.6,1.2,1c0.4,0.5,0.8,1,1.3,1.5c0.1,0,0.1,0.1,0.2,0.1
	c1,0.7,2,1.4,2.1,2.3c0.1,0.7,0.1,1.4,0.1,2.1c0,0.6,0.1,1.5,0.1,1.7c0,0.2,0.1,0.4,0.2,0.5c0.3,0.5,0.4,1.2,0.3,1.8
	c-0.2,0.5-0.4,0.9-0.7,1.3c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.4-0.1,0.9,0.1,1.3c0.6,0.5,1,1.1,1.4,1.7c0.1,0.3,0.1,0.7,0.1,1
	c0,0.1,0,0.4,0.1,0.5c0.4,0.7,0.6,1.4,0.7,2.2v0.1c0,0.7,0,1.4,0.1,2.1l0.4,0.4c1.8,2.1,2.4,2.9,2.6,3.5c0.1,1.2,0.1,2.3,0,3.5
	c-0.1,0.5-0.1,1.1-0.1,1.6v1c0,1.3,0,3.8,0.1,4.4c0.1,0.8,0.4,2.9,0.4,3.8c0,0.9-0.1,1.8-0.4,2.6c-0.2,0.5-0.4,1.1-0.4,1.7
	c0,0.4,0.1,4.9,0.1,7.8l26.5-1.2l-2.2-61.5c0-0.2,0.1-0.5,0.2-0.7c0.8-0.9,1.4-1.9,1.7-3c0.9-5.3,1.2-10.6,1.1-16
	c0-0.5-0.2-1.1-0.5-1.5c-0.3,0.4-0.7,0.7-1.2,0.8c-0.9,0.2-1.8,0.2-2.7-0.2c-0.8-0.5-1.5-1.2-1.8-2.1c0-0.5,0-1,0.2-1.4
	c0.1-0.3,0.2-0.6,0.2-0.9c-1.2-1.1-7.3-5.2-9.7-5.9c-0.8-0.2-1.6,0-2.3,0.4c-0.4,0.3-1,0.5-1.5,0.5c-0.6,0.1-1.1,0.1-1.7,0
	c-0.4-0.1-0.8-0.1-1.2,0c-0.6,0.2-1.3,0.4-1.9,0.7c-0.7,0.3-1.4,0.5-2.1,0.7c-0.1,0-4.6,0.2-5.9-0.8c-0.6-0.4-1.1-0.8-1.5-1.3
	c-4.8,0.4-10.5,0.8-11,0.9c-0.1,0.1-0.2,0.1-0.3,0.1c0,0.2,0.1,0.3,0.1,0.5c0.2,0.7,0.1,1.5-0.1,2.2c-0.2,0.6-0.5,1.2-0.9,1.7
	c-0.1,0.1-0.1,0.2-0.2,0.2l0.1,0.1c0.6,0.6,0.8,1.5,0.7,2.3C72.7,246.1,72.7,246.1,72.7,246.2z"/>
<path className="cls-1" d="M159.9,446c-0.4-1.3-0.9-2.4-1.7-3.5c-1.1-1.5-1.5-1.8-1.7-1.8s-0.5-0.1-0.7-0.2c-0.6-0.1-1.2-0.3-1.7-0.6
	c-0.6-0.4-1.1-1-1.6-1.5c-0.2-0.2-0.3-0.4-0.3-0.7l-0.4-30.8c-0.6,0-1.2,0.1-1.8,0.1c-0.9,0-1.9,0-2.8-0.1c-1.4-0.2-2.3-1.6-3-2.6
	c-0.2-0.2-0.4-0.6-0.5-0.7c-0.1-0.1-0.5-0.2-0.8-0.4c-0.5-0.2-1.1-0.4-1.5-0.8c-0.5-0.6-0.9-1.3-1.1-2.1c-0.1-0.1-0.1-0.3-0.1-0.4
	c-0.4-0.4-0.9-0.6-1.4-0.8c-0.5-0.1-1-0.1-1.5-0.1c-0.6,0-0.9-0.1-1.2-0.1c-0.5-0.1-0.9-0.5-1.7-1.3c-0.5-0.6-1-1.1-1.7-1.5
	c-0.6-0.1-1.3,0-1.9,0.1c-0.6,0.1-1.1,0.2-1.7,0.2c-0.8-0.2-1.6-0.6-2.2-1.1c-0.4-0.3-0.8-0.7-1.1-0.7c-1.2-0.1-2.4-0.1-3.6-0.1
	c-0.9,0-1.7,0-2.6-0.1c-0.9,0-1.9,0.3-2.6,0.9l-0.1,0.1c-0.6,0.3-1.2,0.5-1.9,0.5c-0.3,0-0.7,0.1-0.9,0.1c-0.8,0.1-1.6-0.1-2.3-0.5
	c-0.1,0-0.1,0-0.1-0.1l0,0c-0.1,0.1-0.3,0.2-0.4,0.4c-0.8,0.7-1.3,1.2-1.9,1.3s-1.5-0.3-2.7-1c-0.3-0.2-0.6-0.4-0.9-0.5
	c-0.6,0-1.1,0.1-1.7,0.2c-0.7,0.1-1.4,0.2-2.1,0.2c-0.9-0.2-1.6-0.7-2.1-1.4c-0.1,0.2-0.3,0.4-0.5,0.7c-0.6,1.1-2,0.7-2.5,0.6
	c-0.1,0-0.1,0-0.2-0.1c-0.8,0-1.1-0.7-1.3-1.1c0-0.1-0.1-0.1-0.1-0.2h-0.7c-0.5,0-1,0-1.5-0.2c-0.8-0.4-0.9-1.3-1.1-2.7
	c-0.1-0.2-0.1-0.5-0.1-0.7c-0.6-0.2-1.2-0.4-1.8-0.5c-0.5-0.1-1-0.2-1.5-0.4c-0.6-0.4-1.2-0.9-1.7-1.5c-0.2-0.2-0.3-0.4-0.5-0.5
	c-0.2-0.1-0.9,0.1-1.2,0.1c-0.6,0.1-1.1,0.1-1.7,0c-0.9-0.3-1.8-2.1-2.1-2.8c-0.2-0.4-1.2-1.3-1.6-1.3c-0.2,0-0.4,0.1-0.8,0.4
	c-0.3,0.2-0.6,0.4-1,0.5c-1.2,0.4-2.4,0.4-3.6,0.2c-0.1,0-0.2,0-0.3-0.1c-1-0.1-1.8-1.3-2.8-2.8c-0.1-0.2-0.2-0.4-0.4-0.6
	c-0.6-0.4-1.3-0.7-2-1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.9-0.5-1.5-1.5-1.5-2.6c0-0.8,0.4-1.5,1.1-1.8l0.1-0.1c1-0.5,2.1-1,2.1-2.7
	c0-1.7-2.1-2.3-2.7-2.3h-1.8c-0.8,0-1.7,0.1-2.7,0.1c-0.3,0-0.7,0-1-0.1c0.7,11.4,1.4,21.3,1.4,22.3c0.6,0.6,1.4,1.2,2.2,1.5
	c0.5,0.3,1,0.6,1.5,1c0.5,0.7,0.7,1.6,0.4,2.5c0,0.2-0.1,0.3-0.1,0.5c0.5-0.2,1-0.1,1.4,0.1c0.7,0.5,1,1.3,0.9,2.1
	c-0.2,1.5-1.8,2-2.4,2.1c-0.8,0.1-1.5,0-2.2-0.4c-0.4-0.2-0.9-0.3-1.4-0.4c-1.8,1.2-3.5,2.5-5,4c-0.2,0.5-0.3,1-0.4,1.5
	c-0.2,0.9-0.4,1.7-0.7,2.6c-0.3,0.7-1,1.2-1.8,1.3c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.3-0.4,0.6-0.5,1c-0.1,0.3-0.2,0.5-0.4,0.7
	c-0.5,0.8-1.1,1.5-1.8,2.1l-0.1,0.1c-1.2,1-2.5,1.8-4,2.3c-0.8,0.2-1.6,0-2.1-0.6c-0.2,0.2-0.4,0.5-0.7,0.8c0,0.2-0.1,0.3-0.1,0.5
	c-0.1,0.7-0.3,1.4-0.6,2c-0.6,0.6-1.4,1.1-2.2,1.3c-0.2,0.1-0.4,0.1-0.5,0.2c-1.6,1.2-3.1,2.5-4.5,3.9c-0.3,0.5-0.4,1.1-0.5,1.7
	c-0.1,0.4-0.2,0.8-0.3,1.1c-0.3,0.6-0.7,1.2-1.2,1.7c-0.4,0.4-0.8,0.9-1.2,1.4c-0.2,1.4-0.2,2.7,0.1,4.1c0.1,0.4,0.1,0.9,0.1,1.3
	c0,1.7-3,3.8-3.4,4c-0.8,0.4-1.8,0.6-2.7,0.5c-0.4,0-0.7,0-1.1,0.1c-0.4,0.2-1.8,1.4-2.6,2c-0.6,0.6-1.3,1.1-2,1.5
	c-0.6,0.3-1.3,0.5-1.9,0.7c-0.4,0.1-0.7,0.2-0.8,0.2c-0.8,0.6-1.5,1.2-2.1,2c0.1,0.5,0.2,0.9,0.4,1.4c0.1,0.2,0.1,0.4,0.2,0.5
	c0.2,1.7,0,3.3-0.6,4.9c-0.5,0.8-1.4,1.4-2.4,1.5c-0.1,0-0.1,0-0.2,0.1c-0.7,0.3-1.4,0.7-2.1,1.1c-0.9,0.5-1.9,0.5-2.9,0.2h-0.1
	c-0.5-0.2-1-0.4-1.4-0.7c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0.3-0.2,0.6-0.4,0.9c-0.3,0.3-0.8,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.2-0.2
	c-0.7-0.2-0.9-0.9-1.1-1.4c-0.1-0.1-0.1-0.4-0.2-0.5c-0.7-0.1-1.3-0.1-2,0c0.1,0.6,0.1,1.2,0.2,1.8c0.5,4,0.9,8,1.6,12.1
	c0.2,1,0.6,1.9,1.2,2.8c0.9,1.3,2,2.5,2.9,3.8c0,0.1,0,0.1,0.1,0.1c0,0,0,0,0,0c6.8-0.7,24.5-2.5,36.5-3.5
	c24.9-2.1,66.6-3.4,79.9-3.8c0.6-1.2,0.8-2.6,0.5-4c-0.1-0.6,0-1.2,0.2-1.8c0.6-2.2,0.3-4.6-0.9-6.5c-1.4-2.6-2-5.4-2-8.3
	c-0.1-2.3,0.1-4.5,0.2-6.8s2.8-3.5,5.1-2c-1,0.5-1.8,1-2.7,1.4v0.5c0.7,0.1,1.3,0.2,2,0.4c1.3,0,2.5,0.2,3.7,0.6
	c5.3,2,10.6,4.2,15.9,6.3c4.8,1.9,9.4,1.2,13.8-1.4c0.2-0.1,0.3-0.2,0.5-0.3C160.7,447.3,160.2,446.7,159.9,446z"/>
<path className="cls-1" d="M249.3,122c-0.9-0.8-1.5-1.8-1.7-3c-0.1-1.2-0.4-2.4-0.9-3.5c-0.1-0.1-0.3-0.2-0.5-0.4s-0.4-0.3-0.6-0.5
	c-0.3-0.3-0.5-0.7-0.6-1.1c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3-0.1-0.7-0.2-0.9-0.5c-0.5-0.5-0.8-1.1-0.9-1.7
	c-0.1-0.1-0.1-0.4-0.1-0.4s-0.1-0.1-0.5-0.3c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-1-0.5-1.9-1.1-2.7-1.8
	c-0.9-0.8-1.2-1.1-1.7-1.1c-0.5,0.1-1.1,0.2-1.6,0.4c-0.7,0.3-1.4,0.4-2.1,0.4c-0.9-0.1-1.5-1-2-1.5c-0.1-0.1-0.1-0.2-0.2-0.2
	l-0.4-0.4c-0.4-0.2-0.7-0.6-0.9-1c-0.3-0.7-0.4-1.4-0.4-2.1v-0.3h-0.1c-0.4,0.3-0.9,0.4-1.4,0.5c-0.8,0-1.8-0.7-3.1-2l-0.2-0.2
	c-0.8-1-1.4-2.2-1.9-3.4c-0.1-0.3-0.3-0.7-0.5-1l-0.7-0.7c-0.3-0.2-0.5-0.4-0.7-0.7c-0.3-0.3-0.6-0.7-0.8-1c-0.2-0.3-0.7-0.8-0.8-1
	c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8-0.1-1.2-0.1c-1.4-0.3-3.3-3.3-3.4-3.5c-0.3-0.6-0.6-1.3-0.8-2c-0.3-0.8-0.6-1.6-1-2.3
	c-1.6-1.6-3.3-3.2-5-4.6c-1.1-1-1.6-1.4-1.8-1.6c-0.4-0.2-0.8-0.4-1.2-0.5c-0.3-0.1-0.6-0.2-0.9-0.4c-0.4-0.2-0.7-0.7-1.1-1.1
	c-0.2-0.2-0.4-0.4-0.5-0.7c-0.7-0.4-1.4-0.6-2.2-0.8c-0.6-0.2-1-0.5-1.4-1c-0.3-0.3-0.7-0.6-0.9-0.8c-1.1-1-2-2.2-2.7-3.6
	c-0.2-0.2-0.3-0.4-0.4-0.7c-0.8-0.9-1.8-1.7-2.8-2.3c-0.8-0.4-1.7-0.6-2.6-0.7c-0.6,0-1.1-0.2-1.6-0.4c-0.7-0.4-0.9-1.3-1.1-2.3
	c0-0.1-0.1-0.2-0.1-0.3c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.7-0.1-1.1-0.8-1.5-1.5c-0.1-0.1-0.2-0.4-0.3-0.5h-0.4
	c-0.3,0-0.7,0-1-0.1c-0.4-0.2-0.8-0.4-1.1-0.8c-0.8-0.9-1.9-1.6-3-2c-0.8-0.3-1.7-0.5-2.6-0.7c-0.8-0.2-1.7-0.4-3.3-0.8
	c-0.4-0.1-0.8-0.2-1.1-0.4l0.1,5.1c0,0.2-0.1,0.5-0.2,0.7l-21.4,26.5l34.6-0.4c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7
	l-0.4,54l55.6,1.1v-0.4c0-1,0-1.2,0.1-1.4c0.2-0.5,0.7-0.9,2.1-1.8c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.7-0.7,0.9-2.6
	c0-0.8-0.1-1.6-0.4-2.3c-0.3-1.1-0.4-2.3-0.4-3.4c0.1-1.6,2.6-3.1,2.6-3.2c0.3-0.2,0.8-0.3,1.2-0.5c0.3-0.1,0.6-0.2,0.9-0.3
	c0.3-0.4,0.4-0.8,0.5-1.3v-0.1c0-0.1,0-0.1,0.1-0.2c-0.1-1.3,0.4-2.6,1.3-3.5L249.3,122z"/>
<path className="cls-1" d="M130.7,767.3c-0.5,0.4-1.1,0.7-1.7,0.9c-4,0.3-8-0.1-11.2-2.9c-2-1.7-4-3.2-6-4.8c-0.9-0.7-1.8-1.4-2.6-2.2
	c-1.5-1.6-3.3-2.8-5.3-3.6c-2.9-0.9-5.4-2.9-6.9-5.5c-0.7-1.1-1.7-2.1-2.8-2.8c-1.8-0.9-3.1-2.7-3.4-4.7c-0.3-1.8-1.1-2.4-2.9-2.6
	c-0.4,0-0.7,0-1.1,0.1c-1.8,0-2.8-0.7-3-2.6c-0.1-0.8-0.1-1.5-0.1-2.3c0.1-1,0.4-2,1.4-2.3c1-0.3,1.2,0.9,1.7,1.5
	c0.1,0.1,0.1,0.1,0.4,0.2c0.9-0.2,1.2-0.8,0.8-1.5c-0.2-0.6-0.5-1.1-1-1.5c-2.7-2.3-4.4-5.5-6.2-8.5c-0.4-0.4-0.7-0.8-0.9-1.3
	c-0.1,0.1-0.2,0.1-0.3,0.2c1.2,18.8,2.4,37.5,3.6,56.5c0.8-0.7,1.4-1.1,1.9-1.5c1.8-1.6,4-2.5,6.4-2.6c7.1-0.3,14.3-0.8,21,2.4
	c1.2,0.6,2.7,0.5,3.9-0.1c1.3-0.6,2.6-1.3,3.8-1.9c1.7-1,3.5-1.5,5.2-0.8c2,0.8,3.4,0.1,5-0.8c0.8-0.5,1.1-1,0.9-1.8
	c-0.2-0.9,0.2-1.9,1-2.3c0.5-0.2,0.7-1,1-1.4l-0.4-0.4C132.1,766.6,131.4,766.9,130.7,767.3z"/>
<path className="cls-1" d="M147.7,768.1c-0.4,0.3-0.9,0.4-1.4,0.5c-1.6,0.1-3.3,0.2-4.9,0.1c-0.9-0.2-1.8,0.2-2.4,0.9c-0.4,0.5-0.9,0.9-1.4,1.2
	c0.9,2.2,1.8,2.5,3.3,1.3c0.2-0.1,0.3-0.4,0.5-0.5c0.7-0.8,1.8-1.1,2.8-0.7c0.8,0.1,1.6,0,2.3-0.2c0.8-0.3,1.5-0.5,2.3-0.7
	c1.5-0.2,1.5-0.2,1.7-2C148.8,767.5,148.8,767.6,147.7,768.1z"/>
<path onClick={() => { handleChangeSlide(5);  handleChangeSlide2(5); setProvincia('laMatanza'); setDisplay(!display) }} className="active laMatanza" d="M234.4,324.5l3.5,3.6l2.4-1.3l0.4-1.5c0-0.1,0-0.1,0.1-0.2l1.3-1.8l0.7-1.8c0-0.1,0.1-0.2,0.2-0.3l1-0.6l0.6-1.4l0.5-0.8
	c0-0.1,0.1-0.1,0.2-0.2l0.5-0.3l0.2-0.4c0-0.1,0.1-0.1,0.1-0.1l0.5-0.5l0.1-0.1l0.7-0.4c0.1-0.1,0.3-0.1,0.4-0.1h0.1
	c0.1-0.1,0.2-0.3,0.3-0.3l0.6-0.2l0.7-0.2l0.2-0.3l0.4-0.8c0.2-0.3,0.5-0.4,0.8-0.2l0.8,0.4l0.7-0.9l-0.1-0.1l-3-3
	c-0.2-0.2-0.3-0.4-0.3-0.6l-1.5-1.5l-1.2,0.3l-0.1,0.2c0,0.1-0.1,0.2-0.2,0.3l-3.6,3.6l0.1,0.4c0.1,0.2,0,0.5-0.1,0.6l-0.8,0.7
	c-0.2,0.2-0.4,0.2-0.6,0.1h-0.1l-0.5,0.6l0.6,0.8c0.2,0.3,0.2,0.6-0.1,0.8l-1.1,0.9c-0.2,0.2-0.6,0.2-0.8,0l-0.1-0.1l-1.2,1
	c-0.1,0.1-0.3,0.2-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.2l-1-1.1l-0.5,0.4l2,2.3c0.1,0.1,0.2,0.3,0.1,0.4c0,0.2-0.1,0.3-0.2,0.4l-0.6,0.5
	c-0.1,0.1-0.3,0.1-0.4,0.1h-0.7l-0.7,0.5c-0.1,0.1-0.2,0.1-0.4,0.1h-0.6l0.6,0.6c0.2,0.2,0.2,0.6,0.1,0.8L234.4,324.5z"/>
<path onClick={() => { handleChangeSlide(6);  handleChangeSlide2(6); setProvincia('marDelPlata'); setDisplay(!display) }} className="active marDelPlata" d="M260.5,377.6c0-0.5-0.1-0.9-0.2-1.4c-0.3-1-0.2-2.1,0.5-3v-0.5l-0.1,0l-0.7,0.8c-0.2,0.2-0.4,0.3-0.6,0.2l-0.4-0.1
	c-0.2,0-0.3-0.2-0.4-0.3H258l-0.8,0.3l-0.7,0.2c-0.2,0.1-0.5,0-0.6-0.1l-0.3-0.3l-1.1,0.6l0,0c0.1,0.1,0.2,0.3,0.2,0.5
	c0,0.2-0.1,0.4-0.3,0.5l-0.4,0.3v2.2l0.7,1.8c0.1,0.3,0,0.6-0.3,0.8l-0.2,0.1l1,1.7l0.9,0.4l1.4-0.5
	C260,381.2,260.5,380.3,260.5,377.6z"/>
<path onClick={() => { handleChangeSlide(7);  handleChangeSlide2(7); setProvincia('BuenosAires'); setDisplay(!display) }} className="active BuenosAires" d="M248.6,301.4c-0.1-0.1,1-1.1,1.1-1.4c0.3-0.6-0.2-0.7-0.3-0.9c-0.1,0.3-0.3,0.5-0.5,0.7l-2.3,1.3l-0.7,0.4
	c-0.2,0.2-0.4,0.2-0.7,0.2c-0.3,0-0.5-0.1-0.8-0.2l-0.8-0.5l-0.4,0.2l-0.1,0.4l0.1,0.1c0.2,0.3,0.2,0.7,0.1,1l-0.5,2.1
	c-0.1,0.5-0.5,0.9-1,1c-0.1,0-0.2,0.1-0.4,0.1c-0.4,0-0.7-0.1-1-0.4l-2.9-3c-0.1-0.1-0.2-0.2-0.2-0.3l-1.9-3.5
	c-0.3-0.5-0.2-1.2,0.2-1.6l0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.4c-0.1-0.4,0.1-0.8,0.4-1.1l1.1-1.1c0.2-0.2,0.4-0.3,0.7-0.4l1-2.8
	c0.1-0.4,0.2-0.4,0.6-0.5c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0.1,0.5,0.1l4,1.4l0.9-0.1h0.2c0.1-0.7,1-2.3,1.2-3.2
	c-1-0.2-2.1-0.4-3.1-0.7c-0.7-0.3-1.2-0.9-1.5-1.6c-0.4-0.8-1-1.5-1.7-1.9c-0.9-0.5-1.9-0.8-2.9-0.8c-0.9,0-1.7-0.2-2.4-0.7
	c-0.5-0.4-0.8-0.9-1.1-1.5c-0.2-0.5-0.5-1-0.8-1.1c-0.6-0.2-1.2-0.3-1.8-0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.8-0.1-1.5-0.8-2.6-2.1
	c-0.2-0.2-0.4-0.5-0.5-0.5c-0.7-0.5-1.5-0.8-2.4-1c-0.9-0.1-1.7-0.1-2.6,0c-1.2,0.2-2.4,0.1-3.6-0.2c-1.1-0.4-2-1.2-2.4-2.3
	c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.1-0.2c-0.5-0.8-1.1-1.6-1.8-2.2c-0.4-0.2-0.8-0.3-1.2-0.4c-0.3,0-0.6-0.1-0.9-0.2
	c-0.3-0.1-0.6-0.2-0.9-0.4l-4.5,6.1c-0.3,0.4-0.8,0.5-1.3,0.3c-1-0.6-2-1.1-3-1.5h-2.3c-0.1,0-0.3,0.1-0.4,0.1
	c-0.9,1.1-9.6,11.3-15.3,18.1c-0.2,0.2-0.5,0.4-0.8,0.4l-31.6-0.4l2.1,144l1,1c0.2,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0.6,0.2,0.9,0.2
	c0.8,0.2,1.5,1,2.7,2.6c0.9,1.2,1.5,2.5,2,3.9c0.2,0.7,0.7,0.7,0.7,0.7c0.4,0,0.8,0.3,1,0.7c0.4-0.1,0.7-0.1,1.1-0.1
	c0.5,0,0.9-0.2,1.2-0.6c0.9-0.9,2.1-1.5,3.3-1.8c2.3-0.7,3.6-3.2,2.9-5.6c-0.1-0.4-0.3-0.7-0.5-1c-0.5-0.9-0.9-1.9-1.4-2.9
	c-0.3-0.6-0.6-1.2-1-1.8c-1.1-1.6-1-3.3,0.6-4.3c2-1.3,2.1-2.8,1.5-4.8c-0.7-2.3-0.5-2.7,1.8-3.3c2-0.6,2.4-1.2,2.6-3.2
	c0-1.1,0.1-2.1,0.3-3.2c0.6-2.1-0.1-3.5-2-4.5c-0.3-0.2-0.5-0.3-0.8-0.5c-1.1-0.8-1-1.3,0.2-2.3l2.4,2.1c0.9-0.5,1.1-1.1,0.7-1.8
	c-0.4-0.6-0.8-1-1.4-1.4c-2.8-1.7-3.5-4.3-3-7.4c0.2-1.1-0.1-2.3-0.7-3.2c-0.4-0.7-0.7-1.4-1-2.2c1.9-0.2,2.4,0.1,3.2,1.6
	c0.4,0.7,0.7,1.8,1.2,2c2,1.1,4.1,1.9,6.3,2.3c2.5,0.3,4.9,0.4,7.4,0.2c1.5-0.1,2.9-0.4,4.4-0.7c3-0.5,5.9-1.4,8.8-1.7
	c4.9-0.3,9.9-0.9,14.7-2c2.3-0.6,4.6-1,6.9-1.3c3.2-0.3,6.4-1.2,9.3-2.6c2.6-1.4,5.4-2.3,8.3-2.8c0.9-0.2,1.6-0.6,2.2-1.3
	c0.6-0.8,1.6-1.3,2.6-1.5c2.8-0.8,5.6-1.6,8.3-2.5c0,0-0.1-0.1-0.1-0.1l-1.4-2.4c-0.2-0.3-0.1-0.7,0.2-0.9l0.2-0.2l-0.5-1.4
	c0-0.1,0-0.2,0-0.2v-2.7c0-0.2,0.1-0.4,0.3-0.5l0.1,0l-0.1-0.1c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.3-0.5l2.3-1.3
	c0.2-0.1,0.6-0.1,0.8,0.1l0.4,0.3l0.2-0.1l0.9-0.4c0.1,0,0.2,0,0.3,0h1.2c0.1,0,0.3,0,0.4,0.1l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
	l1.1-0.4c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.3,0.3,0.3,0.5l0.1,0.2c0.8-0.8,1.8-1.4,2.7-2.1c1-0.8,2-1.7,3-2.7
	c2.7-3.6,5.2-7.1,7.8-10.8c1.8-2.7,2.5-5.9,2.1-9.1c-0.3-1.9-0.9-3.9-0.1-5.9c0-0.6-0.3-1.2-0.7-1.7c-0.6-0.1-1.1,0.1-1.5,0.5
	c-0.6,0.7-1.7,0.9-2.5,0.4c-0.4-0.1-0.7-0.4-1.1-0.4c-1.1,0-2-0.7-2.3-1.7c-0.7-1.8-2-3.4-3.6-4.5c-0.5-0.4-0.7-1.1-0.7-1.7
	c-0.2-2.8,0.8-5.6,2.7-7.6c2.9-3,2.5-4.6-0.1-7.9c-2-2.6-4.2-5.2-6.5-7.6c0,0.1,0,0.1-0.1,0.1l0,0c-0.4,0.5-0.8,1-1.2,1.6
	c-0.2,0.4-2.6,1.2-2.7,1.2h-0.1c-0.1,0-1.4-0.2-1.4-0.2c-0.5,0.5-1,1.1-1.4,1.7c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.1-0.1,0.1-0.1,0.2
	l-1.5,1.7c-0.2,0.2-0.5,0.3-0.7,0.2l-0.7-0.3l-0.2,0.3c0,0,0,0.1-0.1,0.1l-0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.2l-1,0.2v0.3
	c0,0.2-0.1,0.4-0.3,0.5l-0.5,0.3c-0.2,0.1-0.3,0.1-0.5,0c-0.2-0.1-0.3-0.2-0.3-0.3l0,0h-0.1l-0.3,0.4l-0.3,0.5
	c-0.1,0.1-0.1,0.2-0.2,0.2l-0.5,0.3l-0.4,0.7l-0.6,1.4c0,0.1-0.1,0.2-0.2,0.3l-1,0.6l-0.7,1.6c0,0,0,0.1-0.1,0.1l-1.2,1.8l-0.4,1.6
	c0,0.2-0.1,0.3-0.3,0.4l-3.1,1.6c-0.2,0.1-0.5,0.1-0.7-0.1l-4.2-4.3c-0.2-0.2-0.2-0.5,0-0.8l0.5-0.6l-1.1-1.1
	c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.2,0.2-0.4,0.4-0.4l0.8-0.2h0.2h0.8l0.7-0.5c0.1-0.1,0.2-0.1,0.4-0.1h0.5l-1.9-2.2
	c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.2,0.1-0.3,0.2-0.4l1.4-1.2c0.2-0.2,0.6-0.2,0.8,0.1l1,1.1l1.1-1c0.2-0.2,0.6-0.2,0.8,0l0.1,0.1
	l0.2-0.2l-0.6-0.7c-0.2-0.2-0.2-0.5,0-0.8l1.1-1.3c0.2-0.2,0.5-0.3,0.7-0.2l0.1,0.1l0.2-0.2l-0.1-0.3c-0.1-0.2,0-0.5,0.1-0.6
	l3.8-3.8l0.1-0.4c0-0.2,0.2-0.4,0.4-0.4l1.9-0.5c0.2-0.1,0.4,0,0.6,0.2l0.8,0.8l0-1.3c0-0.2,0.1-0.3,0.1-0.5c0.6-1.2,1.2-2.4,2-3.6"
	/>
<polygon onClick={() => { handleChangeSlide(8);  handleChangeSlide2(8); setProvincia('tigre'); setDisplay(!display) }} className="active tigre" points="243.5,299.4 245,300.4 245.7,300 247.7,298.9 247.6,298.3 247.4,297.4 246.3,296.1 245.5,293.7 245.4,293.6 
	244.3,293.7 240.3,292 239,295.8 238.4,295.6 237.3,296.7 238,297.2 236.9,298.3 238.7,301.6 241.5,304.5 242,302.4 241.6,301.8 
	242.1,300.4 "/>
<path onClick={() => { handleChangeSlide(9);  handleChangeSlide2(9); setProvincia('caba'); setDisplay(!display) }} className="active caba" d="M255.1,310.1h0.2c0.5,0.1,0.9,0.2,1.4,0.4c0.7-0.2,1.4-0.5,2-0.8c0.2-0.2,1.4-1.7,1.5-1.8H260l-1-1.1l-0.7-1.2l-1.5-0.1
	l-1.2-1H255l-1.1-0.9c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0.1-1.1,0.4-1.2,0.5c-0.1,0.1-1.5,3.3-1.5,3.3l-0.2,2l2.7,2.9
	C254.9,310.4,254.9,310.2,255.1,310.1z"/>
<g>
	<polygon onClick={() => { handleChangeSlide(10);  handleChangeSlide2(10); setProvincia('vicenteLopez'); setDisplay(!display) }} className="active vicenteLopez" points="251.1,301.6 250.7,302.1 251,302.3 251.2,302.3 251.5,302.6 252.1,302 252.1,301.7 251.8,301.5 251.7,301.3 	"/>
</g>
<polygon onClick={() => { handleChangeSlide(11);  handleChangeSlide2(11); setProvincia('sanIsidro'); setDisplay(!display) }} className="active sanIsidro" points="249.4,300.8 249.5,300.8 250.7,299.9 251.2,300.3 251.5,300.7 250.8,301.1 250.5,301.7 249.4,301.1 "/>
<path onClick={() => { handleChangeSlide(12);  handleChangeSlide2(12); setProvincia('tucuman'); setDisplay(!display) }} className="active tucuman" d="M122.2,104.3c-0.8,0.2-1.6,0.2-2.4,0.1h-0.3c0,0.1,0,0.1-0.1,0.1c-0.3,0.8-1,1.5-1.9,1.7c-1,0.1-2-0.2-2.7-0.9
	c-0.1-0.1-0.4-0.2-0.4-0.3c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.7,0.2-1.1,0.3c-1.5,0.1-2.9-0.7-3.5-2.1c-0.3-0.7-0.7-0.9-1.9-1.1
	c-1.7-0.1-3.5-0.1-5.2,0h-0.8c-0.6-0.1-1.2,0-1.7,0.2c0.1,0.2,0.1,0.4,0.2,0.6c0.3,0.6,0.4,1.3,0.4,2c0.1,0.6-0.2,1.2-0.6,1.7
	c-0.4,0.4-0.9,0.6-1.4,0.5h-0.4c-1.3-0.1-2.7-0.5-3.9-1c-0.3-0.1-0.5-0.2-0.8-0.4c-0.1,0.2-0.1,0.3-0.2,0.5
	c-0.1,0.2,0.1,0.2,0.1,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.8-0.3,1.6-0.8,2.3c-0.1,0.2-0.3,0.3-0.4,0.4c-0.3,0.2-0.4,0.4-0.3,1.1
	c0.1,0.7,0.5,0.8,1.2,1c0.1,0,0.2,0.1,0.3,0.1c2.4,0.7,4.1,2.9,4.2,5.4c0.1,2.7-1.7,4.5-3.3,6.2c-0.5,0.5-0.9,1-1.4,1.5
	c-1.2,1.4-2.3,3-3.1,4.7l0.5,0.5c0.1,0,0.2-0.1,0.4-0.1c0.5-0.2,1-0.1,1.4,0.1c1.1,0.5,1.8,1.5,2,2.7c-0.1,0.6-0.3,1.3-0.6,1.8
	c-0.1,0.1-0.1,0.2-0.2,0.4l0.1,0.1l0.1,0.1c0.4,0.4,0.8,0.9,1.2,1.4c0.2,0.3,0.5,0.6,0.8,0.9c0.5,0.6,1.1,1.1,1.7,1.5
	c0.2-0.8,0.9-1.3,1.7-1.4c1.3-0.1,2.4,0.6,2.9,1.8c0.2,0.7,0.2,1.4,0,2.1c-0.1,0.4-0.1,0.7-0.1,1.1c0.2,0.4,0.4,0.8,0.8,1.1
	c0.4,0,0.8-0.1,1.2-0.3c0.4-0.6,0.8-1.2,1.2-1.9c0.2-0.5,0.6-0.9,1-1.3c0.7-0.4,1.5-0.5,2.3-0.2h0.2c0.9,0.3,1.7,0.7,2.4,1.3
	c0.1,0.1,0.1,0.1,0.2,0.1c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.6,0.4-1,1-1.1c0.3,0,0.6,0.1,0.8,0.3c0.3-0.2,0.7-0.4,1.1-0.5
	c0.6-0.2,0.7-0.3,0.8-0.4c0.6-0.5,1-1.3,1.1-2.1c0-0.7-0.2-1.4-0.5-2c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.6,0.1-1.3,0.5-1.8
	c0.1-0.3,0.2-0.5,0.3-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.4-0.2-0.9-0.1-1.3c0.2-0.4,0.5-0.8,0.9-1c0.2-0.1,0.4-0.2,0.5-0.4
	c0.1-0.3,0-0.7-0.1-1c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.5,0.6-0.8,0.9-1l-0.3-0.3c-0.3-0.6-0.4-1.3-0.2-2v-0.2c0.1-0.5,0.4-0.9,0.9-1.1
	c0.3-0.1,0.5-0.3,0.6-0.6c0.5-1.3,0.9-2.6,1.1-4c0.2-0.9,0.4-1.5,0.5-1.9c0.3-0.9,1.4-1.7,2.8-2.6c0.1-0.1,0.3-0.2,0.4-0.3
	C122.8,110.5,122.5,107.3,122.2,104.3z M102.3,133l-1.5,0.9l-1.9-0.7l-1.5-3.4l1.3-1l0.8-0.2l2.3,1l0.1,0.2l-0.1,0.4l0.3-0.1
	l0.5,0.1l0.1,1.6L102.3,133z M108.6,120.5l0.9-0.8l1.4,0.2l-0.1,0.8l0.5,0.1v0.4l0.6,0.1l0.1,1.5l-0.3,0.1v2l-1.9-0.4l-0.1-1.5
	l-0.6-0.2l-0.3,0.4l-0.7-0.4l0.5-2.4l0-0.1"/>
<polyline onClick={() => { handleChangeSlide(13);  handleChangeSlide2(13); setProvincia('bandaDelRio'); setDisplay(!display) }} className="active bandaDelRio" points="109.2,121.1 109.2,121.1 108.9,122.4 109.4,122.6 109.5,122.4 110,122.5 110.2,124.1 111.1,124.3 110.9,122.4 
	111.1,122.4 111.1,121.5 110.7,121.5 110.7,121.3 110.5,121.2 110.5,120.7 109.7,120.6 109.2,121 "/>
<polygon onClick={() => { handleChangeSlide(14);  handleChangeSlide2(14); setProvincia('monteros'); setDisplay(!display) }} className="active monteros" points="101,130.7 100.9,130.8 100.9,130.6 100.8,130.5 99.7,130 99.3,130.1 98.7,130.6 99.5,132.2 100.4,132.6 
	101.1,132.1 101.4,131.6 101.3,130.8 "/>

        </g>
    </g>
    </svg>
  )
}

export default MapComp
